import React from 'react';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import { Loader } from '../../components';
import { getHajjData } from '../../Admin/Home/helper';

export default function Main() {

    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState({
        color: '#FFF',
        tag: 'h1',
        family: 'Barlow',
        align: '',
        s1Title: '',
        s1Image: '',
        s2Title1: '',
        s2Title2: '',
        s2Title2S: '',
        s2Desc: '',
        s3Img: '',
        s3Title1: '',
        s3Title2: '',
        s3Title2S: '',
        s3List: [],
    });

    const fetch = async() => {
        setLoading(true);
        await getHajjData(setData);
        setLoading(false);
    };

    React.useEffect(() => {
        fetch();
    }, []);

    const s2Title2 = React.useMemo(() => data.s2Title2?.toLowerCase()?.replace(data.s2Title2S?.toLowerCase(), `<span>${data.s2Title2S?.toLowerCase()}</span>`));
    const s3Title2 = React.useMemo(() => data.s3Title2?.toLowerCase()?.replace(data.s3Title2S?.toLowerCase(), `<span>${data.s3Title2S?.toLowerCase()}</span>`));

    if(loading) return <Loader/>;

    return (

        <React.Fragment>
            <Section1 s1Image={data.s1Image} align={data.align} color={data.color} family={data.family} tag={data.tag} title={data.s1Title}/>
            <Section2 s2Title1={data.s2Title1} s2Title2={s2Title2} s2Desc={data.s2Desc}/>
            <Section3 s3Title1={data.s3Title1} s3Title2={s3Title2} s3List={data.s3List} s3Img={data.s3Img}/>
        </React.Fragment>

    );

}