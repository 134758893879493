const FIREBASE_API_KEY = 'AIzaSyB94MmeYbAFvbRWjsUhbgcM9bVDx1t7ZCg';
const AUTH_DOMAIN = 'aliftoursltd.firebaseapp.com';
const PROJECT_ID = 'aliftoursltd';
const STORAGE_BUCKET = 'aliftoursltd.appspot.com';
const MESSAGING_SENDER_ID = '443762909502';
const APP_ID = '1:443762909502:web:e8295bb41842c8dd07cc6f';
const MEASUREMENT_ID = 'G-8FTP8PZX7W';

export const firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: AUTH_DOMAIN,
    projectId: PROJECT_ID,
    storageBucket: STORAGE_BUCKET,
    messagingSenderId: MESSAGING_SENDER_ID,
    appId: APP_ID,
    measurementId: MEASUREMENT_ID,
};