import React from 'react';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import { Loader } from '../../components';
import { getHomeData } from '../../Admin/Home/helper';

export default function Main() {

    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState({
        s1List: [],
        s2Title1: '',
        s2Title2: '',
        s2Title2S: '',
        s2Desc: '',
        s2List: [],
        s2Img: '',
        s3Title1: '',
        s3Title2: '',
        s3Title2S: '',
        pckg1: {
            title: '',
            img: '',
            dur: '',
        },
        pckg2: {
            title: '',
            img: '',
            dur: '',
        },
        pckg3: {
            title: '',
            img: '',
            dur: '',
        },
    });

    const s2Title2 = React.useMemo(() => data.s2Title2?.toLowerCase()?.replace(data.s2Title2S?.toLowerCase(), `<span>${data.s2Title2S?.toLowerCase()}</span>`));
    const s3Title2 = React.useMemo(() => data.s3Title2?.toLowerCase()?.replace(data.s3Title2S?.toLowerCase(), `<span>${data.s3Title2S?.toLowerCase()}</span>`));

    const fetch = async() => {
        setLoading(true);
        await getHomeData(setData);
        setLoading(false);
    };

    React.useEffect(() => {
        fetch();
    }, []);

    const len = (data.s1List?.length || 1) - 1;

    if(loading) return <Loader/>;

    return (

        <React.Fragment>
            <Section1 s1List={data.s1List} len={len}/>
            <Section2 s2Title1={data.s2Title1} s2Title2={s2Title2} s2Desc={data.s2Desc} s2List={data.s2List} s2Img={data.s2Img}/>
            <Section3 s3Title1={data.s3Title1} s3Title2={s3Title2} pckg1={data.pckg1} pckg2={data.pckg2} pckg3={data.pckg3}/>
        </React.Fragment>

    );

}