import React from 'react'
import { DeleteForever } from '@mui/icons-material';
import { delNewsLetterClient, getNewsLetterData, DownloadAsCSV } from './helper';
import { IconButton, LinearProgress, Box, TablePagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';

const NewsLetter = () => {

    const [data, setData] = React.useState(['_']);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const arr = React.useMemo(() => rowsPerPage > 0 ? data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data);

    React.useEffect(() => {
        getNewsLetterData(setData);
    }, []);

    return (

        <Box overflow='auto' sx={{ backgroundColor: '#fff9db', width: '100%' }}>
            {data[0]==='_'?<LinearProgress sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 10000 }}/>:null}
            <TableContainer component={Paper} sx={{ backgroundColor: '#2e2d31' }}>
                <TablePagination
                ActionsComponent={() => (
                    <Box mt={-4} width={300} display='flex' alignItems='center' justifyContent='center'>
                        <Button 
                        variant="contained" 
                        onClick={() => DownloadAsCSV(data, 'newsletter')} 
                        sx={{ color: '#cba235', borderRadius: 2, backgroundColor: '#2e2d31', mt: 4 }}>Download</Button>
                    </Box>
                )}
                page={page} 
                component={Paper} 
                count={data?.length} 
                rowsPerPage={rowsPerPage} 
                onPageChange={handleChangePage} 
                onRowsPerPageChange={handleChangeRowsPerPage} 
                sx={{ backgroundColor: '#2e2d31', color: '#d5d5d6' }}/>
                <Table sx={{ width: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 600, color: '#d5d5d6' }}>Sl. no.</TableCell>
                            <TableCell sx={{ fontWeight: 600, color: '#d5d5d6' }}>Email</TableCell>
                            <TableCell sx={{ fontWeight: 600, color: '#d5d5d6' }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {arr?.map((item, i) => (
                            <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row" sx={{ color: '#d5d5d6' }}>{i+1}</TableCell>
                                <TableCell sx={{ color: '#d5d5d6' }}>{item}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => delNewsLetterClient(item)}>
                                        <DeleteForever sx={{ color: '#fc4141' }}/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box height={200}/>
        </Box>

    );

};

export default React.memo(NewsLetter);