import React from 'react';
import parse from 'html-react-parser';

const Section3 = ({s3Img, s3Title1, s3Title2, s3List}) => {

    return (

        <section className="tours1 section-padding bg-lightnav index2 pt-5" data-scroll-index="1">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 mb-5" >
                        <img src={s3Img} width="100%"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="section-subtitle">
                            <span>{s3Title1}</span>
                        </div>
                        <div className="section-title">{parse(s3Title2)}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {s3List?.map((obj, i) => (
                            <p key={i}><b>{obj?.title}</b> {obj?.desc}</p>
                        ))}
                    </div>
                </div>
            </div>
        </section>

    );

}

export default React.memo(Section3);