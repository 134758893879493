import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactVisibilitySensor from 'react-visibility-sensor';

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
};

const Section1 = ({s2List}) => {

    const refArr = React.useRef([]);

    return (

        <section className="about cover section-padding mt-0 tours1" style={{ background: "#FFF4E1"}}>
            <div className="container">
                {s2List?.map((obj, i) => (
                    <div key={i}>
                        <div className="row mt-5" key={i}>
                            <ReactVisibilitySensor partialVisibility onChange={e => {
                                if(e){
                                    refArr.current[i].classList?.add('fadeUp');
                                    refArr.current[i].classList?.remove('anim');
                                }
                            }}>
                                <div className={`col-md-5 ${(i%2)===0?'order-sm-2':''} anim`} ref={ref => refArr.current[i]=ref}>
                                    <div className="country country1 mt-30">
                                        <div className="section-title">{obj?.title}</div>
                                        <p className="text-justify">{obj?.desc}</p>
                                    </div>
                                </div>
                            </ReactVisibilitySensor>
                            <div className="col-md-7">
                                {obj?.images?.length===1?<img className='img-fluid' src={obj?.images[0]} alt="banner"/>:
                                <Slider {...settings}>
                                    {obj?.images?.map((img, x) => (
                                        <div className="item" key={x}>
                                            <div className="position-re o-hidden">
                                                <img src={img} alt="banner"/>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>}
                            </div>
                            {obj?.desc2?.length>0?
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <p className="text-justify p-text">{obj.desc2}</p>
                                </div>
                            </div>:null}
                        </div>
                        <hr/>
                    </div>
                ))}
            </div>
        </section>

    );

}

export default React.memo(Section1);