import React from 'react';
import Section1 from './Section1';
import Section2 from './Section2';
import { Loader } from '../../components';
import { getZiyaratData } from '../../Admin/Home/helper';

export default function Main() {

    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState({
        color: '#FFF',
        tag: 'h1',
        family: 'Barlow',
        align: '',
        s1Title: '',
        s1Image: '',
        s2List: [
            {
                title: '',
                desc: '',
                desc2: '',
                images: [],
            }
        ],
    });

    const fetch = async() => {
        setLoading(true);
        await getZiyaratData(setData);
        setLoading(false);
    };

    React.useEffect(() => {
        fetch();
    }, []);

    if(loading) return <Loader/>;

    return (

        <React.Fragment>
            <Section1 s1Image={data.s1Image} align={data.align} color={data.color} family={data.family} tag={data.tag} title={data.s1Title}/>
            <Section2 s2List={data.s2List}/>
        </React.Fragment>

    );

}