import React from 'react';

const Section3 = ({s3Title1, s3Title2, s3Title2S, s3List, s3Img}) => {

    return (

        <section className="tours1 section-padding bg-lightnav about1" data-scroll-index="1" 
        style={{ background: `url("${s3Img}")`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="section-subtitle">
                            <span className="white1">{s3Title1}</span>
                        </div>
                        <div className="section-title">
                            <span className="white1">{s3Title2}</span><span>{s3Title2S}</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {s3List?.map((obj, i ) => (
                        <div className="col-md-12" key={i}>
                            <p className="white1">
                                <b>{obj?.title}</b>
                            </p>
                            <p className="white1">{obj?.desc}</p>
                        </div>
                    ))}
                    
                </div>
            </div>
        </section>

    );

}

export default React.memo(Section3);