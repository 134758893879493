import React from 'react';
import parse from 'html-react-parser';
import ReactVisibilitySensor from 'react-visibility-sensor';

const Section2 = ({s2Img, s2Title1, s2Title2, s2Desc}) => {

    const refArr = React.useRef([]);

    return (

        <section className="about cover section-padding mt-0 pb-5">
            <div className="container">
                <div className="row">
                    <ReactVisibilitySensor partialVisibility onChange={e => {
                        if(e){
                            refArr.current[0].classList?.add('fadeUp');
                            refArr.current[0].classList?.remove('anim');
                        }
                    }}>
                        <div className="col-md-6 mb-30 anim" ref={ref => refArr.current[0]=ref}>
                            <img src={s2Img} width="100%" alt='img'/>
                        </div>
                    </ReactVisibilitySensor>
                    <ReactVisibilitySensor partialVisibility onChange={e => {
                        if(e){
                            refArr.current[1].classList?.add('fadeUp');
                            refArr.current[1].classList?.remove('anim');
                        }
                    }}>
                        <div className="col-md-6 mb-30 anim mt-4" ref={ref => refArr.current[1]=ref}>
                            <div className="section-subtitle">{s2Title1}</div>
                            <div className="section-title">{parse(s2Title2)}</div>
                            <p>{s2Desc}</p>
                        </div>
                    </ReactVisibilitySensor>
                </div>
            </div>
        </section>

    );

};

export default React.memo(Section2);