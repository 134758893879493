import React from 'react';
import { Call as CallIcon, Email as EmailIcon, LocationOn as LocationIcon, ArrowRightAlt } from '@mui/icons-material';
import { getInTouch } from '../../Admin/Home/helper';
import { useData } from '../../hooks';

const Section2 = ({desc, ph, email, location, pList}) => {

    const fr = useData()?.data?.enquiryEmail;

    const [data, setData] = React.useState({
        name: '', 
        email: '', 
        ph: '', 
        msg: '', 
        num: '', 
        prg: '', 
    });

    const handleChange = React.useCallback((value) => {
        setData(state => ({ ...state, ...value }));
    }, [setData]);

    const onSubmit = (val, fr) => {
        getInTouch(val, fr);
    };

    return (

        <section className="contact section-padding">
            <div className="container">
                <div className="row mb-90">

                    <div className="col-md-6 mb-60">
                        <h3>ALIFTOURS LIMITED</h3>
                        <p>{desc}</p>
                        <div className="phone-call mb-30">
                            <div className="icon">
                                <span className="flaticon-phone-call"></span>
                            </div>
                            <div className="text">
                                <p>Phone</p>
                                <a href={`tel:${ph}`} style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                    <CallIcon sx={{ color: '#cba235' }}/>&nbsp; {ph}
                                </a>
                            </div>
                        </div>
                        <div className="phone-call mb-30">
                            <div className="icon">
                                <span className="flaticon-message"></span>
                            </div>
                            <div className="text">
                                <p>E-Mail Address</p>
                                <a href={`mailto:${email}`} style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                    <EmailIcon sx={{ color: '#cba235' }}/>&nbsp;{email}
                                </a>
                            </div>
                        </div>
                        <div className="phone-call">
                            <div className="icon">
                                <span className="flaticon-placeholder"></span>
                            </div>
                            <div className="text">
                                <p>Location</p>
                                <a href='#' style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                    <LocationIcon sx={{ color: '#cba235' }}/>&nbsp;{location}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-5 mb-30 offset-md-1 mt-4">
                        <div className="sidebar">
                            <div className="right-sidebar">
                                <div className="right-sidebar item">
                                    <h2>Get in touch</h2>
                                    <form method="post" className="right-sidebar item-form contact__form" action="mail.php">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="alert alert-success contact__msg" style={{ display: 'none' }} role="alert"> Your message was sent successfully. </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <input name="name" type="text" placeholder="Your Name" required="" value={data.name} onChange={e => handleChange({ name: e.target.value })}/>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <input name="email" type="email" placeholder="Your Email" required="" value={data.email} onChange={e => handleChange({ email: e.target.value })}/>
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <input name="phone" type="text" placeholder="Your Number" required="" value={data.ph} onChange={e => handleChange({ ph: e.target.value })}/>
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <p>Number of family members and persons interested</p>
                                                <input name="number" type="text" placeholder="Eg: 2" required="" value={data.num} onChange={e => handleChange({ num: e.target.value })} style={{ outline: 'none',  }}/>
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <p>What are you interested to sign up for</p>
                                                {pList?.map((obj, i) => (
                                                    <div className='row' key={i} style={{ alignItems: 'center', flexWrap: 'revert' }}>
                                                        <input className='ml-4' name="program" type="checkbox" required="" value={obj} checked={data.prg === obj} onChange={() => handleChange({ prg: obj })} style={{ marginTop: -16 }}/>
                                                        <p className='ml-4 mr-4'>{obj}</p>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <textarea name="message" id="message" cols="30" rows="4" placeholder="Additional Requirements & Requests" required value={data.msg} onChange={e => handleChange({ msg: e.target.value })}>

                                                </textarea>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="butn-dark mt-30 mb-30"> 
                                                    <a href="#" data-scroll-nav="1" className="btn-1" onClick={e => {
                                                        e.preventDefault();
                                                        onSubmit(data, fr);
                                                    }}>
                                                        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                            Send Message &nbsp;&nbsp;<ArrowRightAlt sx={{ color: '#FFF' }}/>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );

}

export default React.memo(Section2);