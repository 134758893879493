import React from 'react';
import Slider from "react-slick";
import parse from 'html-react-parser';
import "slick-carousel/slick/slick.css";
import { Input2 } from '../../components';
import { MuiColorInput } from 'mui-color-input';
import { uploadFile } from '../../functions';
import "slick-carousel/slick/slick-theme.css";
import { saveZiyaratData, getZiyaratData } from './helper';
import { Box, Typography, LinearProgress, Button, Radio, RadioGroup, FormControlLabel } from '@mui/material';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true
};

const Hajj = () => {

    const inpRef = React.useRef(null);
    const refArr = React.useRef([]);

    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState({
        color: '#FFF',
        tag: 'h1',
        family: 'Barlow',
        align: '',
        s1Title: '',
        s1Image: '',
        s2List: [
            {
                title: '',
                desc: '',
                desc2: '',
                images: [],
            }
        ],
    });

    const handleChange = React.useCallback((value) => {
        setData(state => ({ ...state, ...value }));
    }, [setData]);

    const handleListChange = React.useCallback((i, obj, val) => {
        setData(state => ({ 
            ...state, 
            s2List: [...state.s2List?.map((item, index) => index===i? { ...item, [obj]: val } : item)]
        }));
    }, [setData]);

    const addToList = () => {
        setData(state => ({
            ...state,
            s2List: [...state.s2List, {
                title: '',
                desc: '',
                desc2: '',
                images: [],
            }]
        }));
    };

    const removeFromList = (n) => {
        setData(state => ({
            ...state,
            s2List: [...state.s2List?.filter((x, i) => i!==n)],
        }));
    };

    const handleListImage = async(i, val) => {
        const file= val?.target?.files[0];
        setLoading(true);
        if(file){
            const url = URL.createObjectURL(file);
            const uri = await uploadFile(url, `files/${Date.now()}_${file?.name}`);
            setData(state => {
                const arr = [...state.s2List];
                arr[i].images.push(uri);
                return {
                    ...state,
                    s2List: arr
                };
            });
        }
        setLoading(false);
    };

    const removeImg = (i, x) => {
        setData(state => {
            const arr = [...state.s2List];
            arr[i].images.splice(x, 1);
            return {
                ...state,
                s2List: arr
            };
        });
    };

    const fetch = async() => {
        setLoading(true);
        await getZiyaratData(setData);
        setLoading(false);
    };

    React.useEffect(() => {
        fetch();
    }, []);

    const section1 = React.useMemo(() => (
        <React.Fragment>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 6 }}>Section 1</Typography>
            <Box width='95%' position='relative'>
                <img src={data.s1Image} className="w-100" alt="banner"/>
                <Box width='100%' position='absolute' bottom={0} zIndex={100} padding={2} display='flex' justifyContent={data.align} id='alif-banner'>
                    {parse(`<${data.tag} style="color: ${data.color}; font-family: ${data.family}; ">${data.s1Title}</${data.tag}>`)}
                </Box>
            </Box>
            <Button 
            variant="contained" 
            onClick={() => inpRef.current?.click()} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 2 }}>Change image</Button>
            <Box height={20}/>
            <br/>
            <Input2 placeholder='Title' value={data.s1Title} onChange={e => handleChange({ s1Title: e })}/>
            <Box height={6}/>
            <MuiColorInput format="hex" value={data.color} onChange={e => handleChange({ color: e })}/>
            <RadioGroup row defaultValue="Barlow" name="radio-buttons-group" value={data.family} onChange={e => handleChange({ family: e.target.value })}>
                <FormControlLabel value="Barlow" control={<Radio />} label="Barlow" />
                <FormControlLabel value="Playfair Display" control={<Radio />} label="Playfair Display" />
            </RadioGroup>
            <RadioGroup row defaultValue="h1" name="radio-buttons-group" value={data.tag} onChange={e => handleChange({ tag: e.target.value })}>
                <FormControlLabel value="h1" control={<Radio />} label="h1" />
                <FormControlLabel value="h2" control={<Radio />} label="h2" />
                <FormControlLabel value="h3" control={<Radio />} label="h3" />
                <FormControlLabel value="h4" control={<Radio />} label="h4" />
                <FormControlLabel value="h5" control={<Radio />} label="h5" />
                <FormControlLabel value="h6" control={<Radio />} label="h6" />
            </RadioGroup>
            <RadioGroup row defaultValue="flex-start" name="radio-buttons-group" value={data.align} onChange={e => handleChange({ align: e.target.value })}>
                <FormControlLabel value="flex-start" control={<Radio />} label="left" />
                <FormControlLabel value="center" control={<Radio />} label="center" />
                <FormControlLabel value="flex-end" control={<Radio />} label="right" />
            </RadioGroup>
        </React.Fragment>
    ));

    const section2 = React.useMemo(() => (
        <Box width='100%' position='relative'>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 6, mt: 6 }}>Section 2</Typography>
            <Box width='95%'>
                <section className="about cover section-padding mt-0 tours1" style={{ background: "#FFF4E1"}}>
                    <div className="container">
                        {data.s2List?.map((obj, i) => (
                            <div key={i}>
                                <div className="row mt-5 ">
                                    <div className="col-md-5">
                                        <div className="country country1 mt-30">
                                            <div className="section-title">{obj.title}</div>
                                            <p className="text-justify">{obj.desc}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        {obj?.images?.length===1?
                                        <div className="item">
                                            <div className="position-re o-hidden">
                                                <img src={obj?.images[0]} alt="banner"/>
                                                <br/>
                                                <Button 
                                                variant="contained" 
                                                onClick={() => removeImg(i, 0)} 
                                                sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 1 }}>Remove</Button>
                                            </div>
                                        </div>:
                                        <Slider {...settings}>
                                            {obj?.images?.map((img, x) => (
                                                <div className="item" key={x}>
                                                    <div className="position-re o-hidden">
                                                        <img src={img} alt="banner"/>
                                                        <Button 
                                                        variant="contained" 
                                                        onClick={() => removeImg(i, x)} 
                                                        sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 1 }}>Remove</Button>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>}
                                    </div>
                                </div>
                                {obj.desc2?.length>0?
                                <div className="row mt-5">
                                    <div className="col-md-12">
                                        <p className="text-justify">{obj.desc2}</p>
                                    </div>
                                </div>:null}
                                <Input2 placeholder='Title' sx={{ mt: 2 }} value={obj?.title} onChange={e => handleListChange(i, 'title', e)}/>
                                <br/>
                                <Input2 multiline placeholder='Side Description' sx={{ mt: 2 }} value={obj?.desc} onChange={e => handleListChange(i, 'desc', e)}/>
                                <br/>
                                <Input2 multiline placeholder='Bottom Description' sx={{ mt: 2 }} value={obj?.desc2} onChange={e => handleListChange(i, 'desc2', e)}/>
                                <br/>
                                <Button 
                                variant="contained" 
                                onClick={() => refArr.current[i]?.click()} 
                                sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 4 }}>Select image</Button>
                                <Button 
                                variant="contained" 
                                onClick={() => removeFromList(i)} 
                                sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', ml: 4, mt: 4 }}>Remove</Button>
                                <input ref={ref => refArr.current[i]=ref} id='file-input' type='file' accept='image/*' style={{ display: 'none' }} onChange={e =>  handleListImage(i, e)}/>
                                <hr/>
                            </div>
                        ))}
                        </div>
                </section>
            </Box>
            <Button 
            variant="contained" 
            onClick={addToList} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 4 }}>Add new section</Button>
        </Box>
    ));

    return (

        <Box className='admin-div-body'>
            {loading?<LinearProgress sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 10000 }}/>:null}
            {section1}
            {section2}
            <Button 
            variant="contained" 
            onClick={async() => {
                setLoading(true);
                await saveZiyaratData(data);
                setLoading(false);
            }} 
            sx={{ color: '#cba235', borderRadius: 30, backgroundColor: '#2e2d31', position: 'absolute', bottom: 20, right: 20, p: 3.5, fontWeight: 'bold' }}>Save</Button>
            <Box height={200}/>
        </Box>

    );

}

export default React.memo(Hajj);