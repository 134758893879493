import React from 'react';
import './Style.css';
import './Animation.css';
import Nav from './Navigation';
import { DataProvider } from './hooks';

function App() {

    return (

        <DataProvider>
            <Nav/>
        </DataProvider>

    );

}

export default App;