import { storage } from "../hooks";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

export const uploadFile = async(url, path, noAlert) => {
    try{

        const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = () => {
                resolve(xhr.response);
            };
            xhr.onerror = () => {
                alert('Network request failed');
                reject();
            };
            xhr.responseType = "blob";
            xhr.open("GET", url, true);
            xhr.send(null);
        });

        const storageRef = ref(storage, path);
        await uploadBytes(storageRef, blob);

        const downloadURL = await getDownloadURL(storageRef);
        if(!noAlert) alert('Upload complete');
        return downloadURL;

    }catch(e){
        alert(e);
        return null;
    }
};