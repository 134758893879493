import React from 'react';
import { useData } from '../../hooks';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import ReactVisibilitySensor from 'react-visibility-sensor';

const Section3 = ({s3Title1, s3Title2, pckg1, pckg2, pckg3}) => {

    const { data } = useData();

    const refArr = React.useRef([]);

    return (

        <section className="tours1 section-padding bg-lightnav index2" data-scroll-index="1">
            <div className="container">

                <div className="row">
                    <div className="col-md-12">
                        <div className="section-subtitle">
                            <span>{s3Title1}</span>
                        </div>
                        <div className="section-title">{parse(s3Title2)}</div>
                    </div>
                </div>

                <div className="row">

                    {data?.hajj?
                    <ReactVisibilitySensor partialVisibility onChange={e => {
                        if(e){
                            refArr.current[0].classList?.add('fadeUp');
                            refArr.current[0].classList?.remove('anim');
                        }
                    }}>
                        <div className="col-md-8 anim" ref={ref => refArr.current[0]=ref}>
                            <div className="item">
                                <div className="position-re o-hidden">
                                    <img src={pckg1?.img} alt="banner"/>
                                </div>
                                <span className="category">
                                    <Link to='/hajj'>{pckg1?.dur}</Link>
                                </span>
                                <div className="con">
                                    <h5>
                                        <Link to='/hajj'>{pckg1?.title}</Link>
                                    </h5>
                                    <div className="line"></div>
                                </div>
                            </div>
                        </div>
                    </ReactVisibilitySensor>:null}

                    {data?.umrah?
                    <ReactVisibilitySensor partialVisibility onChange={e => {
                        if(e){
                            refArr.current[1].classList?.add('fadeUp');
                            refArr.current[1].classList?.remove('anim');
                        }
                    }}>
                        <div className="col-md-4 anim" ref={ref => refArr.current[1]=ref}>
                            <div className="item">
                                <div className="position-re o-hidden">
                                    <img src={pckg2?.img} alt="banner"/>
                                </div>
                                <span className="category">
                                    <Link to='/umrah'>{pckg2?.dur}</Link>
                                </span>
                                <div className="con">
                                    <h5>
                                        <Link to='/umrah'>{pckg3?.title}</Link>
                                    </h5>
                                    <div className="line"></div>
                                </div>
                            </div>
                        </div>
                    </ReactVisibilitySensor>:null}

                    {data?.ziyarat?
                    <ReactVisibilitySensor partialVisibility onChange={e => {
                        if(e){
                            refArr.current[2].classList?.add('fadeUp');
                            refArr.current[2].classList?.remove('anim');
                        }
                    }}>
                        <div className="col-md-4 anim" ref={ref => refArr.current[2]=ref}>
                            <div className="item">
                                <div className="position-re o-hidden">
                                    <img src={pckg3?.img} alt="banner"/>
                                </div>
                                <span className="category">
                                    <Link to='/ziyarat'>{pckg3?.dur}</Link>
                                </span>
                                <div className="con">
                                    <h5>
                                        <Link to='/ziyarat'>{pckg3?.title}</Link>
                                    </h5>
                                    <div className="line"></div>
                                </div>
                            </div>
                        </div>
                    </ReactVisibilitySensor>:null}

                </div>
            </div>
        </section>

    );

};

export default React.memo(Section3);