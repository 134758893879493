import React from 'react';
import { useData } from './hooks';
import { Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { subToNewsLetter } from './Admin/Home/helper';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { Call as CallIcon, Email as EmailIcon, LocationOn as LocationIcon, Send as SendIcon } from '@mui/icons-material';

const Footer = () => {

    const location = useLocation();
    const { data, loading } = useData();

    const d = new Date();
    const year = d.getFullYear();

    const refArr = React.useRef([]);

    const [email, setEmail] = React.useState('');

    const onSubmit = (val, from) => {
        subToNewsLetter(val, from);
    };

    if(location.pathname==='/admin') return null;
    else if(loading) return null;

    return (

        <div className="footer clearfix">
            <div className="container">

                <div className="first-footer">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="links dark footer-contact-links">
                                <div className="footer-contact-links-wrapper">

                                    <ReactVisibilitySensor partialVisibility onChange={e => {
                                        if(e){
                                            refArr.current[0].classList?.add('zoomIn');
                                            refArr.current[0].classList?.remove('anim');
                                        }
                                    }}>
                                        <div ref={ref => refArr.current[0]=ref} className="footer-contact-link-wrapper anim">
                                            <div className="image-wrapper footer-contact-link-icon">
                                                <div className="icon-footer"> 
                                                    <CallIcon color='inherit' sx={{ width: '42px', height: '42px' }}/>
                                                </div>
                                            </div>
                                            <div className="footer-contact-link-content">
                                                <a href={`tel:${data?.phone}`}>
                                                    <h6>Call us</h6>
                                                    <p>{data?.phone}</p>
                                                </a>
                                            </div>
                                        </div>
                                    </ReactVisibilitySensor>

                                    <div className="footer-contact-links-divider"></div>

                                    <ReactVisibilitySensor partialVisibility onChange={e => {
                                        if(e){
                                            refArr.current[1].classList?.add('zoomIn');
                                            refArr.current[1].classList?.remove('anim');
                                        }
                                    }}>
                                        <div ref={ref => refArr.current[1]=ref} className="footer-contact-link-wrapper anim">
                                            <div className="image-wrapper footer-contact-link-icon">
                                                <div className="icon-footer">
                                                    <EmailIcon color='inherit' sx={{ width: '42px', height: '42px' }}/>
                                                </div>
                                            </div>
                                            <div className="footer-contact-link-content">
                                                <a href={`mailto:${data?.email}`}>
                                                    <h6>Write to us</h6>
                                                    <p>{data?.email}</p>
                                                </a>
                                            </div>
                                        </div>
                                    </ReactVisibilitySensor>

                                    <div className="footer-contact-links-divider"></div>

                                    <ReactVisibilitySensor partialVisibility onChange={e => {
                                        if(e){
                                            refArr.current[2].classList?.add('zoomIn');
                                            refArr.current[2].classList?.remove('anim');
                                        }
                                    }}>
                                        <div ref={ref => refArr.current[2]=ref} className="footer-contact-link-wrapper anim">
                                            <div className="image-wrapper footer-contact-link-icon">
                                                <div className="icon-footer">
                                                    <LocationIcon color='inherit' sx={{ width: '42px', height: '42px' }}/>
                                                </div>
                                            </div>
                                            <div className="footer-contact-link-content">
                                                <h6>Address</h6>
                                                <p>{data?.location}</p>
                                            </div>
                                        </div>
                                    </ReactVisibilitySensor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="second-footer">
                    <div className="row">

                        <ReactVisibilitySensor partialVisibility onChange={e => {
                            if(e){
                                refArr.current[3].classList?.add('fadeUp');
                                refArr.current[3].classList?.remove('anim');
                            }
                        }}>
                            <div ref={ref => refArr.current[3]=ref} className="col-md-4 widget-area anim">
                                <div className="widget clearfix">
                                    <div className="footer-logo">
                                        <h2>ALIF TOURS LIMITED</h2>
                                    </div>
                                    <div className="widget-text">
                                        <p>{data?.footerDesc}</p>
                                    </div>
                                </div>
                            </div>
                        </ReactVisibilitySensor>

                        <ReactVisibilitySensor partialVisibility onChange={e => {
                            if(e){
                                refArr.current[4].classList?.add('fadeUp');
                                refArr.current[4].classList?.remove('anim');
                            }
                        }}>
                            <div ref={ref => refArr.current[4]=ref} className="col-md-3 offset-md-1 widget-area anim">
                                <div className="widget clearfix usful-links">
                                    <h3 className="widget-title">Quick Links</h3>
                                    <ul>
                                        <li><Link to='/'>Home</Link></li>
                                        <li><Link to='/about'>About</Link></li>
                                        {data?.hajj?<li><Link to='/hajj'>Hajj</Link></li>:null}
                                        {data?.umrah?<li><Link to='/umrah'>Umrah</Link></li>:null}
                                        {data?.ziyarat?<li><Link to='/ziyarat'>Ziyarat</Link></li>:null}
                                        <li><Link to='/contact'>Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </ReactVisibilitySensor>

                        <ReactVisibilitySensor partialVisibility onChange={e => {
                            if(e){
                                refArr.current[5].classList?.add('fadeUp');
                                refArr.current[5].classList?.remove('anim');
                            }
                        }}>
                            <div ref={ref => refArr.current[5]=ref} className="col-md-4 widget-area anim">
                                <div className="widget clearfix">
                                    <h3 className="widget-title">Newsletter</h3>
                                    <p>Sign up for our monthly blogletter to stay informed about latest packages</p>
                                    <div className="widget-newsletter">
                                        <form action="#0">
                                            <input type="email" placeholder="Enter Your Email Address" required="" value={email} onChange={e => setEmail(e.target.value)}/>
                                            <button type="submit" onClick={e => {
                                                e.preventDefault();
                                                onSubmit(email, data?.enquiryEmail);
                                            }}>
                                                <SendIcon color='inherit' />
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </ReactVisibilitySensor>
                    </div>
                </div>

                <div className="bottom-footer-text" >
                    <div className="row copyright">
                        <div className="col-md-12" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <p className="mb-0">© {year} <a href="#">ALIFTOURS LIMITED</a>. All rights reserved.</p>
                            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                <p className="mb-0">
                                    <Link to='/admin'>Admin</Link>
                                </p>
                            </Box>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );

};

export default React.memo(Footer);