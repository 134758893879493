import React from 'react';
import parse from 'html-react-parser';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { Call as CallIcon, Done as DoneIcon } from '@mui/icons-material';
import { useData } from '../../hooks';

const Section2 = ({s2Title1, s2Title2, s2Desc, s2List, s2Img}) => {

    const refArr = React.useRef([]);

    const { data } = useData();

    return (

        <section className="about cover section-padding">
            <div className="container">
                <div className="row">
                    <ReactVisibilitySensor partialVisibility onChange={e => {
                        if(e){
                            refArr.current[0].classList?.add('fadeUp');
                            refArr.current[0].classList?.remove('anim');
                        }
                    }}>
                        <div className="col-md-6 mb-30 anim" ref={ref => refArr.current[0]=ref}>
                            <div className="section-subtitle">{s2Title1}</div>
                            <div className="section-title">{parse(s2Title2)}</div>
                            <p>{s2Desc}</p>
                            <ul className="list-unstyled about-list mb-30">
                                {s2List?.map((obj, i) => (
                                    <li key={i}>
                                        <div className="about-list-icon">
                                            <span className="fas fa-check" style={{ borderRadius: 15, width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <DoneIcon sx={{ color: '#FFF', width: '14px', height: '14px' }}/>
                                            </span>
                                        </div>
                                        <div className="about-list-text">
                                            <p>{obj}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <div className="phone-call mb-30">
                                <div className="icon">
                                    <span>
                                        <CallIcon sx={{ width: '42px', height: '42px', color: '#cba235' }}/>
                                    </span>
                                </div>
                                <div className="text">
                                    <p>For information</p>
                                    <a href={`tel:${data?.phone}`}>{data?.phone}</a>
                                </div>
                            </div>
                        </div>
                    </ReactVisibilitySensor>
                    <div className="col-md-5 offset-md-1 animate-box fadeInUp animated mt-4" data-animate-effect="fadeInUp">
                        <div className="img-exp">
                            <div className="about-img">
                                <div className="img">
                                    <ReactVisibilitySensor partialVisibility onChange={e => {
                                        if(e){
                                            refArr.current[1].classList?.add('fadeUp');
                                            refArr.current[1].classList?.remove('anim');
                                        }
                                    }}>
                                        <img src={s2Img} className="img-fluid trs anim" alt="banner" ref={ref => refArr.current[1]=ref}/>
                                    </ReactVisibilitySensor>
                                </div>
                            </div>
                            <div id="circle">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="300px" height="300px" viewBox="0 0 300 300" enableBackground="new 0 0 300 300" xmlSpace="preserve">
                                    <defs>
                                        <path id="circlePath" d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0"></path>
                                    </defs>
                                    <circle cx="150" cy="100" r="75" fill="none"></circle>
                                    <g>
                                        <use xlinkHref="#circlePath" fill="none"></use>
                                        <text fill="#0f2454">
                                            <textPath xlinkHref="#circlePath">
                                                {" "}. ALIFTOURS LIMITED . ALIFTOURS{" "}
                                            </textPath>
                                        </text>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );

}

export default React.memo(Section2);