import React from 'react';
import parse from 'html-react-parser';
import { Box } from '@mui/material';

const Section1 = ({s1Image, color, tag, family, align, title}) => {

    return (

        <section className="inner-banner">
            <img src={s1Image} className="w-100" alt="banner"/>
            <Box width='100%' position='absolute' bottom={0} zIndex={100} padding={2} justifyContent={align} id='alif-banner' sx={{ display: { xs: 'none', md: 'flex' } }}>
                {parse(`<${tag} style="color: ${color}; font-family: ${family};">${title}</${tag}>`)}
            </Box>
        </section>

    );

}

export default React.memo(Section1);