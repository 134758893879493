import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Admin } from './Admin';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { About, Contact, Hajj, Home, Umrah, Ziyarat } from './screens';
import { useData } from './hooks';
import { Loader } from './components';

function Navigation() {

    const { loading, data } = useData();

    return (

        <div className="App">
            {loading?<Loader/>:null}
            <BrowserRouter>
                <Header/>
                <Routes >
                    <Route path="/" element={<Home/>}/>
                    <Route path="/about" element={<About/>}/>
                    {data?.hajj?<Route path="/hajj" element={<Hajj/>}/>:null}
                    {data?.umrah?<Route path="/umrah" element={<Umrah/>}/>:null}
                    {data?.ziyarat?<Route path="/ziyarat" element={<Ziyarat/>}/>:null}
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/admin" element={<Admin/>}/>
                </Routes>
                <Footer/>
            </BrowserRouter>
        </div>

    );

};

export default Navigation;