import React from 'react';
import Slider from "react-slick";
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import { Input2 } from '../../components';
import { uploadFile } from '../../functions';
import "slick-carousel/slick/slick-theme.css";
import { MuiColorInput } from 'mui-color-input';
import { getHomeData, saveHomeData } from './helper';
import { DeleteForever, Done as DoneIcon, AutoAwesome } from '@mui/icons-material';
import { Box, Typography, LinearProgress, Button, IconButton, Radio, RadioGroup, FormControlLabel, Accordion, AccordionDetails, AccordionSummary, Stack, Slider as Slide } from '@mui/material';

const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
};

const Home = () => {

    const inpRef = React.useRef(null);
    const inpRef2 = React.useRef(null);
    const imgIndex = React.useRef(0);

    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState({
        s1List: [
            {
                title1: '',
                title2: '',
                title3: '',
                img: '',
                isVid: false, 
                align: 'center',
                pattern: [5,3,2],
                font1: {
                    color: '#ffc93b',
                    size: 13,
                    family: 'Barlow'
                },
                font2: {
                    color: '#fff',
                    size: 75,
                    family: 'Barlow'
                },
                font3: {
                    color: '#fff',
                    size: 75,
                    family: 'Barlow'
                },
            }
        ],
        s2Title1: '',
        s2Title2: '',
        s2Title2S: '',
        s2Desc: '',
        s2List: [],
        s2Img: '',
        s3Title1: '',
        s3Title2: '',
        s3Title2S: '',
        pckg1: {
            title: '',
            img: '',
            dur: '',
        },
        pckg2: {
            title: '',
            img: '',
            dur: '',
        },
        pckg3: {
            title: '',
            img: '',
            dur: '',
        },
    });

    const s2Title2 = React.useMemo(() => data.s2Title2?.toLowerCase()?.replace(data.s2Title2S?.toLowerCase(), `<span>${data.s2Title2S?.toLowerCase()}</span>`));
    const s3Title2 = React.useMemo(() => data.s3Title2?.toLowerCase()?.replace(data.s3Title2S?.toLowerCase(), `<span>${data.s3Title2S?.toLowerCase()}</span>`));

    const addToS1List = (uri, isVid) => {
        setData(state => ({
            ...state,
            s1List: [...state.s1List, {
                title1: '',
                title2: '',
                title3: '',
                img: uri,
                isVid: isVid, 
                align: 'center',
                pattern: [5,3,2],
                font1: {
                    color: '#ffc93b',
                    size: 13,
                    family: 'Barlow'
                },
                font2: {
                    color: '#fff',
                    size: 75,
                    family: 'Barlow'
                },
                font3: {
                    color: '#fff',
                    size: 75,
                    family: 'Barlow'
                },
            }]
        }));
    };

    const removeFromS1List = (i) => {
        setData(state => ({
            ...state,
            s1List: [...state.s1List.filter((x, index) => index!==i)]
        }));
    };

    const handleS1List = React.useCallback((i, obj, val) => {
        setData(state => ({
            ...state,
            s1List: [...state.s1List.map((item, index) => index===i? { ...item, [obj]: val }:item)],
        }));
    }, [setData]);

    const handleS1ListFont = React.useCallback((i, obj, val, font) => {
        setData(state => ({
            ...state,
            s1List: [...state.s1List.map((item, index) => index===i? { ...item, [font]: { ...item?.[font], [obj]: val } }:item)],
        }));
    }, [setData]);

    const handleImage = async(val) => {
        const file= val?.target?.files[0];
        setLoading(true);
        const type = file?.type;
        const regex = /^video\//;
        const isVid = regex.test(type);
        if(file){
            const url = URL.createObjectURL(file);
            const uri = await uploadFile(url, `files/${Date.now()}_${file?.name}`);
            switch(imgIndex.current){
                case 0:
                    addToS1List(uri, isVid);
                    break;
                case 1:
                    setData(state => ({
                        ...state,
                        s2Img: uri,
                    }));
                    break;
                case 2:
                    setData(state => ({
                        ...state,
                        pckg1: { ...state.pckg1, img: uri },
                    }));
                    break;
                case 3:
                    setData(state => ({
                        ...state,
                        pckg2: { ...state.pckg2, img: uri },
                    }));
                    break;
                case 4:
                    setData(state => ({
                        ...state,
                        pckg3: { ...state.pckg3, img: uri },
                    }));
                    break;
                default:
                    break;
            }
        }
        setLoading(false);
    };

    const addToList = () => {
        const value = prompt('Add new list');
        if(value?.trim()?.length>0){
            setData(state => ({
                ...state,
                s2List: [...state.s2List, value],
            }));
        }
    };

    const removeFromList = (n) => {
        setData(state => ({
            ...state,
            s2List: [...state.s2List?.filter((x, i) => i!==n)],
        }));
    };

    const handleChange = React.useCallback((value) => {
        setData(state => ({ ...state, ...value }));
    }, [setData]);

    const fetch = async() => {
        setLoading(true);
        await getHomeData(setData);
        setLoading(false);
    };

    React.useEffect(() => {
        fetch();
    }, []);

    const section1 = React.useMemo(() => (
        <React.Fragment>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 6 }}>Slideshow</Typography>
            <Box width='95%' height='500px' overflow='hidden' bgcolor='#e9e3c6'>
                <Slider className='admin-slider' {...settings}>
                    {data?.s1List?.map((obj, i) => {
                        let align = 'center';
                        if(obj.align==='center') align = 'center';
                        else if(obj.align==='left') align = 'flex-start';
                        else if(obj.align==='right') align = 'flex-end';
                        if(obj.isVid){
                            return (
                                <Box key={i} sx={{ position: 'relative', width: '100%', height: '500px' }} className='slider-item'>
                                    <video autoPlay loop muted width='100%' height='100%'>
                                        <source src={obj.img}/>
                                    </video>
                                    <div className="carousel-caption d-md-block">
                                        <Box width='100%' height='100%' display='flex' 
                                        flexDirection='column' alignItems={align} justifyContent='center'>
                                            {obj.pattern[0]===5?
                                            parse(`<h5 class="carousel-caption-h5" style="font-size: ${obj.font1.size}px; color: ${obj.font1.color}; font-family: ${obj.font1.family}; text-align: ${obj.align};">${obj.title1}</h5>`):
                                            obj.pattern[0]===3?
                                            parse(`<h3 class="carousel-caption-h3" style="font-size: ${obj.font2.size}px; color: ${obj.font2.color}; font-family: ${obj.font2.family};">${obj.title2}</h3>`):
                                            obj.pattern[0]===2?
                                            parse(`<h2 class="carousel-caption-h2" style="font-size: ${obj.font3.size}px; -webkit-text-stroke: 1px ${obj.font3.color}; font-family: ${obj.font3.family};">${obj.title3}</h2>`)
                                            :null}
                                            {obj.pattern[1]===5?
                                            parse(`<h5 class="carousel-caption-h5" style="font-size: ${obj.font1.size}px; color: ${obj.font1.color}; font-family: ${obj.font1.family}; text-align: ${obj.align};">${obj.title1}</h5>`):
                                            obj.pattern[1]===3?
                                            parse(`<h3 class="carousel-caption-h3" style="font-size: ${obj.font2.size}px; color: ${obj.font2.color}; font-family: ${obj.font2.family};">${obj.title2}</h3>`):
                                            obj.pattern[1]===2?
                                            parse(`<h2 class="carousel-caption-h2" style="font-size: ${obj.font3.size}px; -webkit-text-stroke: 1px ${obj.font3.color}; font-family: ${obj.font3.family};">${obj.title3}</h2>`)
                                            :null}
                                            {obj.pattern[2]===5?
                                            parse(`<h5 class="carousel-caption-h5" style="font-size: ${obj.font1.size}px; color: ${obj.font1.color}; font-family: ${obj.font1.family}; text-align: ${obj.align};">${obj.title1}</h5>`):
                                            obj.pattern[2]===3?
                                            parse(`<h3 class="carousel-caption-h3" style="font-size: ${obj.font2.size}px; color: ${obj.font2.color}; font-family: ${obj.font2.family};">${obj.title2}</h3>`):
                                            obj.pattern[2]===2?
                                            parse(`<h2 class="carousel-caption-h2" style="font-size: ${obj.font3.size}px; -webkit-text-stroke: 1px ${obj.font3.color}; font-family: ${obj.font3.family};">${obj.title3}</h2>`)
                                            :null}
                                        </Box>
                                    </div>
                                </Box>
                            );
                        }
                        return (
                            <Box key={i} sx={{ backgroundImage: `url("${obj.img}")`, position: 'relative', width: '100%', height: '500px' }} className='slider-item'>
                                <div className="carousel-caption d-md-block">
                                    <Box width='100%' height='100%' display='flex' 
                                    flexDirection='column' alignItems={align} justifyContent='center'>
                                        {obj.pattern[0]===5?
                                        parse(`<h5 class="carousel-caption-h5" style="font-size: ${obj.font1.size}px; color: ${obj.font1.color}; font-family: ${obj.font1.family}; text-align: ${obj.align};">${obj.title1}</h5>`):
                                        obj.pattern[0]===3?
                                        parse(`<h3 class="carousel-caption-h3" style="font-size: ${obj.font2.size}px; color: ${obj.font2.color}; font-family: ${obj.font2.family};">${obj.title2}</h3>`):
                                        obj.pattern[0]===2?
                                        parse(`<h2 class="carousel-caption-h2" style="font-size: ${obj.font3.size}px; -webkit-text-stroke: 1px ${obj.font3.color}; font-family: ${obj.font3.family};">${obj.title3}</h2>`)
                                        :null}
                                        {obj.pattern[1]===5?
                                        parse(`<h5 class="carousel-caption-h5" style="font-size: ${obj.font1.size}px; color: ${obj.font1.color}; font-family: ${obj.font1.family}; text-align: ${obj.align};">${obj.title1}</h5>`):
                                        obj.pattern[1]===3?
                                        parse(`<h3 class="carousel-caption-h3" style="font-size: ${obj.font2.size}px; color: ${obj.font2.color}; font-family: ${obj.font2.family};">${obj.title2}</h3>`):
                                        obj.pattern[1]===2?
                                        parse(`<h2 class="carousel-caption-h2" style="font-size: ${obj.font3.size}px; -webkit-text-stroke: 1px ${obj.font3.color}; font-family: ${obj.font3.family};">${obj.title3}</h2>`)
                                        :null}
                                        {obj.pattern[2]===5?
                                        parse(`<h5 class="carousel-caption-h5" style="font-size: ${obj.font1.size}px; color: ${obj.font1.color}; font-family: ${obj.font1.family}; text-align: ${obj.align};">${obj.title1}</h5>`):
                                        obj.pattern[2]===3?
                                        parse(`<h3 class="carousel-caption-h3" style="font-size: ${obj.font2.size}px; color: ${obj.font2.color}; font-family: ${obj.font2.family};">${obj.title2}</h3>`):
                                        obj.pattern[2]===2?
                                        parse(`<h2 class="carousel-caption-h2" style="font-size: ${obj.font3.size}px; -webkit-text-stroke: 1px ${obj.font3.color}; font-family: ${obj.font3.family};">${obj.title3}</h2>`)
                                        :null}
                                    </Box>
                                </div>
                            </Box>
                        )
                    })}
                </Slider>
            </Box>
            <Box width='95%' display='flex' flexDirection='row' mt={4} sx={{ overflowX: 'auto' }}>
                {data?.s1List?.map((obj, i) => (
                    <Box mr={2} key={i}>
                        <Box width={410} height={180} borderRadius={2} display='flex' alignItems='center' justifyContent='center' sx={{ backgroundImage: `url("${obj.img}")`, backgroundSize: 'cover' }}>
                            {obj.isVid?<Typography variant='body1' sx={{ color: '#585858', fontWeight: 400, fontFamily: 'inherit' }}>Video</Typography>:null}
                        </Box>
                        <br/>
                        <Accordion>
                            <AccordionSummary expandIcon={<AutoAwesome sx={{ ml: 2 }}/>}>
                                <Input2 placeholder='Slideshow title 1' value={obj.title1} onChange={e => handleS1List(i, 'title1', e)}/>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup row defaultValue="Barlow" name="radio-buttons-group" value={obj.font1.family} onChange={e => handleS1ListFont(i, 'family', e.target.value, 'font1')}>
                                    <FormControlLabel value="Barlow" control={<Radio />} label="Barlow" />
                                    <FormControlLabel value="Playfair Display" control={<Radio />} label="Playfair Display" />
                                </RadioGroup>
                                <Stack spacing={2} direction="row" alignItems="center" sx={{ width: 300 }}>
                                    <Slide value={obj.font1.size} onChange={(o, e) => handleS1ListFont(i, 'size', e, 'font1')}/>
                                    <Typography variant='body2'>{obj.font1.size}</Typography>
                                </Stack>
                                <MuiColorInput format="hex" value={obj.font1.color} onChange={e => handleS1ListFont(i, 'color', e, 'font1')}/>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<AutoAwesome sx={{ ml: 2 }}/>}>
                                <Input2 placeholder='Slideshow title 2' value={obj.title2} onChange={e => handleS1List(i, 'title2', e)}/>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup row defaultValue="Barlow" name="radio-buttons-group" value={obj.font2.family} onChange={e => handleS1ListFont(i, 'family', e.target.value, 'font2')}>
                                    <FormControlLabel value="Barlow" control={<Radio />} label="Barlow" />
                                    <FormControlLabel value="Playfair Display" control={<Radio />} label="Playfair Display" />
                                </RadioGroup>
                                <Stack spacing={2} direction="row" alignItems="center" sx={{ width: 300 }}>
                                    <Slide value={obj.font2.size} onChange={(o, e) => handleS1ListFont(i, 'size', e, 'font2')}/>
                                    <Typography variant='body2'>{obj.font2.size}</Typography>
                                </Stack>
                                <MuiColorInput format="hex" value={obj.font2.color} onChange={e => handleS1ListFont(i, 'color', e, 'font2')}/>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<AutoAwesome sx={{ ml: 2 }}/>}>
                                <Input2 placeholder='Slideshow title 3' value={obj.title3} onChange={e => handleS1List(i, 'title3', e)}/>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup row defaultValue="Barlow" name="radio-buttons-group" value={obj.font3.family} onChange={e => handleS1ListFont(i, 'family', e.target.value, 'font3')}>
                                    <FormControlLabel value="Barlow" control={<Radio />} label="Barlow" />
                                    <FormControlLabel value="Playfair Display" control={<Radio />} label="Playfair Display" />
                                </RadioGroup>
                                <Stack spacing={2} direction="row" alignItems="center" sx={{ width: 300 }}>
                                    <Slide value={obj.font3.size} onChange={(o, e) => handleS1ListFont(i, 'size', e, 'font3')}/>
                                    <Typography variant='body2'>{obj.font3.size}</Typography>
                                </Stack>
                                <MuiColorInput format="hex" value={obj.font3.color} onChange={e => handleS1ListFont(i, 'color', e, 'font3')}/>
                            </AccordionDetails>
                        </Accordion>
                        <RadioGroup row defaultValue="center" name="radio-buttons-group" value={obj.align} onChange={e => handleS1List(i, 'align', e.target.value)}>
                            <FormControlLabel value="left" control={<Radio />} label="Left" />
                            <FormControlLabel value="center" control={<Radio />} label="Center" />
                            <FormControlLabel value="right" control={<Radio />} label="Right" />
                        </RadioGroup>
                        <RadioGroup row defaultValue="5,3,2" name="radio-buttons-group" value={`${obj.pattern[0]},${obj.pattern[1]},${obj.pattern[2]}`} onChange={e => handleS1List(i, 'pattern', e.target.value.split(",").map(Number))}>
                            <FormControlLabel value="5,2,3" control={<Radio />} label="1,2,3" />
                            <FormControlLabel value="5,3,2" control={<Radio />} label="1,3,2" />
                            <FormControlLabel value="2,5,3" control={<Radio />} label="2,1,3" />
                            <FormControlLabel value="2,3,5" control={<Radio />} label="2,3,1" />
                            <FormControlLabel value="3,5,2" control={<Radio />} label="3,1,2" />
                            <FormControlLabel value="3,2,5" control={<Radio />} label="3,2,1" />
                        </RadioGroup>
                        <Button 
                        variant="contained" 
                        onClick={() => removeFromS1List(i)} 
                        sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 1 }}>Remove</Button>
                    </Box>
                ))}
            </Box>
            <Button 
            variant="contained" 
            onClick={() => {
                imgIndex.current = 0;
                inpRef2.current?.click();
            }} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 4, mb: 1 }}>Add</Button>
            <br/>
            <Typography variant='subtitle1' sx={{ color: '#585858', fontWeight: 'bold', fontFamily: 'inherit', mt: 1 }}>Only MP4 Video formats are supported!</Typography>
        </React.Fragment>
    ));

    const section2 = React.useMemo(() => (
        <React.Fragment>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mt: 6, mb: 6 }}>Section 2</Typography>
            <Box width='95%' minHeight='200px'>
                <section className="about cover section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mb-30">
                                <div className="section-subtitle">{data.s2Title1}</div>
                                    <div className="section-title">{parse(s2Title2 || '')}</div>
                                    <p>{data.s2Desc}</p>
                                    <ul className="list-unstyled about-list mb-30">
                                        {data?.s2List?.map((obj, i) => (
                                            <li key={i}>
                                                <div className="about-list-icon">
                                                    <span className="fas fa-check" style={{ borderRadius: 15, width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <DoneIcon sx={{ color: '#FFF', width: '14px', height: '14px' }}/>
                                                    </span>
                                                </div>
                                                <div className="about-list-text">
                                                    <p>{obj}</p>
                                                </div>
                                                <IconButton onClick={() => removeFromList(i)}>
                                                    <DeleteForever sx={{ color: '#ABABAB' }}/>
                                                </IconButton>
                                            </li>
                                        ))}
                                    </ul>
                            </div>
                            <div className="col-md-5 offset-md-1 animate-box fadeInUp animated" data-animate-effect="fadeInUp">
                                <div className="img-exp">
                                    <div className="about-img">
                                        <div className="img">
                                            <img src={data.s2Img} className="img-fluid trs" alt="banner"/>
                                        </div>
                                    </div>
                                    <div id="circle">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="300px" height="300px" viewBox="0 0 300 300" enableBackground="new 0 0 300 300" xmlSpace="preserve">
                                            <defs>
                                                <path id="circlePath" d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0"></path>
                                            </defs>
                                            <circle cx="150" cy="100" r="75" fill="none"></circle>
                                            <g>
                                                <use xlinkHref="#circlePath" fill="none"></use>
                                                <text fill="#0f2454">
                                                    <textPath xlinkHref="#circlePath">
                                                        {" "}. ALIFTOURS LIMITED . ALIFTOURS{" "}
                                                    </textPath>
                                                </text>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Box>
            <Input2 placeholder='Title 1' sx={{ mt: 4 }} value={data.s2Title1} onChange={e => handleChange({ s2Title1: e })}/>
            <Input2 placeholder='Title 2' sx={{ ml: 4 }} value={data.s2Title2} onChange={e => handleChange({ s2Title2: e })}/>
            <Input2 placeholder='Part of Title 2 to be in secondary color' sx={{ ml: 4 }} value={data.s2Title2S} onChange={e => handleChange({ s2Title2S: e })}/>
            <br/>
            <Input2 multiline placeholder='Description' sx={{ mt: 2 }} value={data.s2Desc} onChange={e => handleChange({ s2Desc: e })}/>
            <br/>
            <Button 
            variant="contained" 
            onClick={addToList} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 4 }}>Add new list</Button>
            <Button 
            variant="contained" 
            onClick={() => {
                imgIndex.current = 1;
                inpRef.current?.click();
            }} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 4, ml: 4 }}>Select image</Button>
        </React.Fragment>
    ));

    const section3 = React.useMemo(() => (
        <React.Fragment>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mt: 6, mb: 6 }}>Section 3</Typography>
            <Box width='95%' minHeight='200px'>
                <section className="tours1 section-padding bg-lightnav index2" data-scroll-index="1">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-subtitle">
                                    <span>{data.s3Title1}</span>
                                </div>
                                <div className="section-title">{parse(s3Title2 || '')}</div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-8">
                                <div className="item">
                                    <div className="position-re o-hidden">
                                        <img src={data.pckg1?.img} alt="banner"/>
                                    </div>
                                    <span className="category">
                                        <Link to='/hajj'>{data.pckg1?.dur}</Link>
                                    </span>
                                    <div className="con">
                                        <h5>
                                            <Link to='/hajj'>{data.pckg1?.title}</Link>
                                        </h5>
                                        <div className="line"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="item">
                                    <div className="position-re o-hidden">
                                        <img src={data.pckg2?.img} alt="banner"/>
                                    </div>
                                    <span className="category">
                                        <Link to='/umrah'>{data.pckg2?.dur}</Link>
                                    </span>
                                    <div className="con">
                                        <h5>
                                            <Link to='/umrah'>{data.pckg2?.title}</Link>
                                        </h5>
                                        <div className="line"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="item">
                                    <div className="position-re o-hidden">
                                        <img src={data.pckg3?.img} alt="banner"/>
                                    </div>
                                    <span className="category">
                                        <Link to='/ziyarat'>{data.pckg3?.dur}</Link>
                                    </span>
                                    <div className="con">
                                        <h5>
                                            <Link to='/ziyarat'>{data.pckg3?.title}</Link>
                                        </h5>
                                        <div className="line"></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </Box>
            <Input2 placeholder='Title 1' value={data.s3Title1} onChange={e => handleChange({ s3Title1: e })}/>
            <Input2 placeholder='Title 2' sx={{ ml: 4 }} value={data.s3Title2} onChange={e => handleChange({ s3Title2: e })}/>
            <Input2 placeholder='Part of Title 2 to be in secondary color' sx={{ ml: 4 }} value={data.s3Title2S} onChange={e => handleChange({ s3Title2S: e })}/>

            <Typography variant='body1' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mt: 6, mb: 1 }}>Package 1</Typography>
            <Input2 placeholder='Title' value={data.pckg1?.title} onChange={e => handleChange({ pckg1: { ...data.pckg1, title: e } })}/>
            <Input2 placeholder='Duration' sx={{ ml: 4 }} value={data.pckg1?.dur} onChange={e => handleChange({ pckg1: { ...data.pckg1,dur: e } })}/>
            <Button 
            variant="contained" 
            onClick={() => {
                imgIndex.current = 2;
                inpRef.current?.click();
            }} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', ml: 4 }}>Select image</Button>

            <Typography variant='body1' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mt: 6, mb: 1 }}>Package 2</Typography>
            <Input2 placeholder='Title' value={data.pckg2?.title} onChange={e => handleChange({ pckg2: { ...data.pckg2, title: e } })}/>
            <Input2 placeholder='Duration' sx={{ ml: 4 }} value={data.pckg2?.dur} onChange={e => handleChange({ pckg2: { ...data.pckg2, dur: e } })}/>
            <Button 
            variant="contained" 
            onClick={() => {
                imgIndex.current = 3;
                inpRef.current?.click();
            }} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', ml: 4 }}>Select image</Button>

            <Typography variant='body1' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mt: 6, mb: 1 }}>Package 3</Typography>
            <Input2 placeholder='Title' value={data.pckg3?.title} onChange={e => handleChange({ pckg3: { ...data.pckg3, title: e } })}/>
            <Input2 placeholder='Duration' sx={{ ml: 4 }} value={data.pckg3?.dur} onChange={e => handleChange({ pckg3: { ...data.pckg3, dur: e } })}/>
            <Button 
            variant="contained" 
            onClick={() => {
                imgIndex.current = 4;
                inpRef.current?.click();
            }} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', ml: 4 }}>Select image</Button>
        </React.Fragment>
    ));

    return (

        <Box className='admin-div-body'>
            {loading?<LinearProgress sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 10000 }}/>:null}
            {section1}
            {section2}
            {section3}
            <input ref={inpRef} id='file-input' type='file' accept='image/*' style={{ display: 'none' }} onChange={e =>  handleImage(e)}/>
            <input ref={inpRef2} id='file-input' type='file' accept='video/mp4,image/*' style={{ display: 'none' }} onChange={e =>  handleImage(e)}/>
            <Button
            variant="contained" 
            onClick={async() => {
                setLoading(true);
                await saveHomeData(data);
                setLoading(false);
            }} 
            sx={{ color: '#cba235', borderRadius: 30, backgroundColor: '#2e2d31', position: 'absolute', bottom: 20, right: 20, p: 3.5, fontWeight: 'bold' }}>Save</Button>
            <Box height={200}/>
        </Box>

    );

}

export default React.memo(Home);