import React from 'react';
import NewsLetter from './NewsLetter';
import { Tab, Tabs, Box, Typography } from '@mui/material';
import Reg from './Reg';
import Touch from './Touch';
import AddAdmin from './AddAdmin';

function CustomTabPanel(props) {

    const { children, value, index, ...other } = props;

    return (

        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (<Box sx={{ p: 3 }}><Typography>{children}</Typography></Box>)}
        </div>

    );

};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const More = () => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => setValue(newValue);

    return (

        <Box className='admin-div-body' sx={{ width: '100%', height: '100vh !important' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Newsletter" {...a11yProps(0)} />
                    <Tab label="Registered (in hajj page)" {...a11yProps(1)} />
                    <Tab label="Get in touch" {...a11yProps(2)} />
                    <Tab label="Admins" {...a11yProps(3)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <NewsLetter/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Reg/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <Touch/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <AddAdmin/>
            </CustomTabPanel>
        </Box>

    );

};

export default React.memo(More);