import React from 'react';
import parse from 'html-react-parser';
import { ArrowRightAlt } from '@mui/icons-material';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { regForHajj } from '../../Admin/Home/helper';
import { useData } from '../../hooks';

const Section2 = ({s2Title1, s2Title2, s2Desc}) => {

    const fr = useData()?.data?.enquiryEmail;

    const refArr = React.useRef([]);

    const [data, setData] = React.useState({
        name: '',
        email: '',
        ph: '',
        sub: '',
        msg: '',
    });

    const handleChange = React.useCallback((value) => {
        setData(state => ({ ...state, ...value }));
    }, [setData]);

    const onSubmit = (val, fr) => {
        regForHajj(val, fr);
    };

    return (

        <section className="about cover section-padding mt-0">
            <div className="container">
                <div className="row">
                    <ReactVisibilitySensor partialVisibility onChange={e => {
                        if(e){
                            refArr.current[0].classList?.add('fadeUp');
                            refArr.current[0].classList?.remove('anim');
                        }
                    }}>
                        <div className="col-md-6 mb-30 anim" ref={ref => refArr.current[0]=ref}>
                            <div className="section-subtitle">{s2Title1}</div>
                            <div className="section-title">{parse(s2Title2)}</div>
                            <p>{s2Desc}</p>
                        </div>
                    </ReactVisibilitySensor>
                    <div className="col-md-5 mb-30 offset-md-1">
                        <div className="sidebar">
                            <div className="right-sidebar">
                                <div className="right-sidebar item">
                                    <h2>Register here</h2>
                                    <form method="post" className="right-sidebar item-form contact__form" action="mail.php">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="alert alert-success contact__msg" style={{ display: 'none' }} role="alert"> Your message was sent successfully. </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <input name="name" type="text" placeholder="Your Name" required="" value={data.name} onChange={e => handleChange({ name: e.target.value })}/>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <input name="email" type="email" placeholder="Your Email" required="" value={data.email} onChange={e => handleChange({ email: e.target.value })}/>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <input name="phone" type="text" placeholder="Your Number" required="" value={data.ph} onChange={e => handleChange({ ph: e.target.value })}/>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <input name="subject" type="text" placeholder="Subject" required="" value={data.sub} onChange={e => handleChange({ sub: e.target.value })}/>
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <textarea name="message" id="message" cols="30" rows="4" placeholder="Message" required value={data.msg} onChange={e => handleChange({ msg: e.target.value })}>

                                                </textarea>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="butn-dark mt-30 mb-30"> 
                                                    <a href="#" data-scroll-nav="1" className="btn-1" onClick={e => {
                                                        e.preventDefault();
                                                        onSubmit(data, fr);
                                                    }}>
                                                        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                            Send Message &nbsp;&nbsp;<ArrowRightAlt sx={{ color: '#FFF' }}/>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );

}

export default React.memo(Section2);