import React from 'react';
import { auth } from '../../hooks';
import { LogInUser } from './helper';
import { logoImg } from '../../assets';
import { Input } from '../../components';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Card, CardContent, CardMedia, Typography, Button, CircularProgress, Box, Dialog } from '@mui/material';

const Main = () => {

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState({
        email: '',
        passwd: '',
    });

    const handleChange = React.useCallback((value) => {
        setData(state => ({ ...state, ...value }));
    }, [setData]);

    const handleAuth = async(email, passwd) => {
        setLoading(true);
        const result = await LogInUser(email, passwd);
        if(result) alert(result);
        setLoading(false);
    };

    const reset = async(e) => {
        await sendPasswordResetEmail(auth, e).then(() => {
            alert('Password reset email sent');
            setOpen(false);
        }).catch((e)=> {
            setOpen(false);
            alert(`Error: ${e}`);
        });
    };

    return (

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100vh' }}>
            <Card sx={{ width: 400, backgroundColor: '#2e2d31' }}>
                <CardMedia
                component="img"
                height="194"
                image={logoImg} 
                alt='logo'
                sx={{ backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', objectFit: 'contain', mt: 4 }}/>
                <CardContent sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 0 }}>
                    <Typography variant='h6' sx={{ color: '#cba235', fontWeight: 600, mt: 2, fontFamily: 'inherit' }}>Admin Log In</Typography>
                    <Input
                    sx={{ mt: 2 }} 
                    type='email' 
                    value={data.email} 
                    placeholder='Email Address'
                    onChange={e => handleChange({ email: e })}/>
                    <Input
                    sx={{ mt: 2 }} 
                    type='password' 
                    value={data.passwd} 
                    placeholder='Password'
                    onChange={e => handleChange({ passwd: e })}/>
                    <Box sx={{  mt: 2, color: '#cba235' }}>
                        {loading?<CircularProgress size='24px' color='inherit'/>:
                        <Button 
                        variant="contained" 
                        onClick={() => handleAuth(data.email, data.passwd)}
                        sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#cba235', width: 300, fontFamily: 'inherit' }}>Log In</Button>}
                    </Box>
                    <Typography 
                    onClick={() => setOpen(true)}
                    variant='caption' 
                    sx={{ 
                        color: '#cba235', 
                        fontWeight: 400, 
                        mt: 2, 
                        cursor: 'pointer',
                        fontFamily: 'inherit' 
                    }}>
                        Forgot/Reset Password
                    </Typography>
                </CardContent>
            </Card>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <Card sx={{ width: 380, backgroundColor: '#2e2d31' }}>
                    <CardContent sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 0 }}>
                        <Input
                        sx={{ mt: 2 }} 
                        type='email' 
                        value={data.email} 
                        placeholder='Email Address'
                        onChange={e => handleChange({ email: e })}/>
                        <Button 
                        variant="contained" 
                        onClick={() => reset(data.email)}
                        sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#cba235', width: 300, mt: 2, fontFamily: 'inherit' }}>Send email to reset password</Button>
                    </CardContent>
                </Card>
            </Dialog>
        </Box>

    );

};

export default React.memo(Main);