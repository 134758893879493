import React from 'react';
import parse from 'html-react-parser';
import { Done as DoneIcon } from '@mui/icons-material';
import ReactVisibilitySensor from 'react-visibility-sensor';

const Section2 = ({s2Title1, s2Title2, s2Desc, s2List, s2Img, collabTitle, collabImg, hide}) => {

    const refArr = React.useRef([]);

    const len = React.useMemo(() => collabTitle?.length);
    const half = React.useMemo(() => Math.ceil(len/2));
    const firstHalf = React.useMemo(() => collabTitle?.substring(0, half));
    const secondHalf = React.useMemo(() => collabTitle?.substring(half));

    return (

        <section className="about cover section-padding mt-0">
            <div className="container">
                <div className="row">
                    <ReactVisibilitySensor partialVisibility onChange={e => {
                        if(e){
                            refArr.current[0].classList?.add('fadeUp');
                            refArr.current[0].classList?.remove('anim');
                        }
                    }}>
                        <div className="col-md-6 mb-30 anim" ref={ref => refArr.current[0]=ref}>
                            <div className="section-subtitle">{s2Title1}</div>
                            <div className="section-title">{parse(s2Title2)}</div>
                            <p>{s2Desc}</p>
                            <ul className="list-unstyled about-list mb-30">
                                {s2List?.map((obj, i) => (
                                    <li key={i}>
                                        <div className="about-list-icon">
                                            <span className="fas fa-check" style={{ borderRadius: 15, width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <DoneIcon sx={{ color: '#FFF', width: '14px', height: '14px' }}/>
                                            </span>
                                        </div>
                                        <div className="about-list-text">
                                            <p>{obj}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            {hide?null:
                            <div className="butn-dark mt-30 mb-30">
                                <span style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', color: '#0f2454', fontSize: 26, fontFamily:"'Barlow', sans-serif", fontWeight: 400 }}>{firstHalf}<span style={{ color: '#cca234' }}>{secondHalf}</span></span>
                                <img src={collabImg} height='100' width='auto' style={{ marginTop: 6 }} alt="banner"/>
                            </div>}
                        </div>
                    </ReactVisibilitySensor>
                    <div className="col-md-5 offset-md-1 animate-box fadeInUp animated mt-4" data-animate-effect="fadeInUp">
                        <div className="img-exp">
                            <div className="about-img">
                                <div className="img">
                                    <ReactVisibilitySensor partialVisibility onChange={e => {
                                        if(e){
                                            refArr.current[1].classList?.add('fadeUp');
                                            refArr.current[1].classList?.remove('anim');
                                        }
                                    }}>
                                        <img ref={ref => refArr.current[1]=ref} src={s2Img} className="img-fluid trs anim" alt="banner"/>
                                    </ReactVisibilitySensor>
                                </div>
                            </div>
                            <div id="circle">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="300px" height="300px" viewBox="0 0 300 300" enableBackground="new 0 0 300 300" xmlSpace="preserve">
                                    <defs>
                                        <path id="circlePath" d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0"></path>
                                    </defs>
                                    <circle cx="150" cy="100" r="75" fill="none"></circle>
                                    <g>
                                        <use xlinkHref="#circlePath" fill="none"></use>
                                        <text fill="#0f2454">
                                            <textPath xlinkHref="#circlePath">
                                                {" "}. ALIFTOURS LIMITED . ALIFTOURS{" "}
                                            </textPath>
                                        </text>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );

}

export default React.memo(Section2);