import React from 'react';
import { logoImg } from './assets';
import { IconButton, Fab } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { Menu as MenuIcon, WhatsApp as WhatsAppIcon } from '@mui/icons-material';
import { useData } from './hooks';

const Header = () => {

    const location = useLocation();
    const { data } = useData();

    const [active, setActive] = React.useState(0);

    const redirect = (ph) => {
        window.location.href = `https://wa.me/${ph}`;
    };

    React.useEffect(() => {
        switch(location.pathname) {
            case '/':
                setActive(0);
                break;
            case '/about':
                setActive(1);
                break;
            case '/hajj':
                setActive(2);
                break;
            case '/umrah':
                setActive(3);
                break;
            case '/ziyarat':
                setActive(4);
                break;
            case '/contact':
                setActive(5);
                break;
            case '/admin':
                setActive(6);
                break;
            default:
                setActive(0);
                break;
        }
    }, [location.pathname]);

    if(location.pathname==='/admin') return null;

    return (

        <header id='header-body'>
            <div id='header-main'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <nav className="navbar navbar-expand-xl justify-content-end">
                                <Link className="navbar-brand" to='/'>
                                    <img src={logoImg} className="logo" alt="logo"/>
                                </Link>
                                <IconButton className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                                    <MenuIcon sx={{ color: '#FFF' }}/>
                                </IconButton>
                                <div className="collapse navbar-collapse  flex-grow-0" id="collapsibleNavbar">
                                    <ul className="navbar-nav text-right">
                                        <li className="nav-item">
                                            <Link className={`nav-link ${active===0?"active":''}`} to='/'>Home</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={`nav-link ${active===1?"active":''}`} to='/about'>About</Link>
                                        </li>
                                        {data?.hajj?
                                        <li className="nav-item">
                                            <Link className={`nav-link ${active===2?"active":''}`} to='/hajj'>Hajj</Link>
                                        </li>:null}
                                        {data?.umrah?
                                        <li className="nav-item">
                                            <Link className={`nav-link ${active===3?"active":''}`} to='/umrah'>Umrah</Link>
                                        </li>:null}
                                        {data?.ziyarat?
                                        <li className="nav-item">
                                            <Link className={`nav-link ${active===4?"active":''}`} to='/ziyarat'>Ziyarat</Link>
                                        </li>:null}
                                        <li className="nav-item">
                                            <Link className={`nav-link ${active===5?"active":''}`} to='/contact'>Contact Us</Link>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <Fab onClick={() => redirect(data?.wa)}
            sx={{ position: 'fixed', bottom: 26, left: 26, backgroundColor: '#2e2d31 !important', color: '#cba235 !important' }}>
                <WhatsAppIcon color='inherit'/>
            </Fab>
        </header>

    );

}

export default React.memo(Header);