import React from 'react';
import Section1 from './Section1';
import Section2 from './Section2';
import { Loader } from '../../components';
import { getData } from '../../Admin/Home/helper';

export default function Main() {

    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState({
        color: '#FFF',
        tag: 'h1',
        family: 'Barlow',
        align: '',
        s1Title: '',
        contactUsImage: '',
        contactUsDesc: '',
        phone: '',
        email: '',
        location: '',
        footerDesc: '',
        pList: [],
    });

    const fetch = async() => {
        setLoading(true);
        await getData(setData);
        setLoading(false);
    };

    React.useEffect(() => {
        fetch();
    }, []);

    if(loading) return <Loader/>;

    return (

        <React.Fragment>
            <Section1 s1Image={data.contactUsImage} align={data.align} color={data.color} family={data.family} tag={data.tag} title={data.s1Title}/>
            <Section2 desc={data.contactUsDesc} email={data.email} ph={data.phone} location={data.location} pList={data.pList}/>
        </React.Fragment>

    );

}