import React from 'react';
import parse from 'html-react-parser';
import { Input2 } from '../../components';
import { uploadFile } from '../../functions';
import { MuiColorInput } from 'mui-color-input';
import { getData, saveData } from './helper';
import { DeleteForever } from '@mui/icons-material';
import { Box, Typography, LinearProgress, Button, Switch, FormGroup, Radio, RadioGroup, FormControlLabel, IconButton } from '@mui/material';

const Contact = () => {

    const inpRef = React.useRef(null);

    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState({
        color: '#FFF',
        tag: 'h1',
        family: 'Barlow',
        align: '',
        s1Title: '',
        contactUsImage: '',
        contactUsDesc: '',
        phone: '',
        email: '',
        location: '',
        footerDesc: '',
        enquiryEmail: '',
        wa: '',
        hajj: true,
        umrah: true,
        ziyarat: true,
        pList: [],
    });

    const handleChange = React.useCallback((value) => {
        setData(state => ({ ...state, ...value }));
    }, [setData]);

    const handleImage = async(val) => {
        const file= val?.target?.files[0];
        setLoading(true);
        if(file){
            const url = URL.createObjectURL(file);
            const uri = await uploadFile(url, `files/${Date.now()}_${file?.name}`);
            setData(state => ({
                ...state,
                contactUsImage: uri
            }));
        }
        setLoading(false);
    };

    const fetch = async() => {
        setLoading(true);
        await getData(setData);
        setLoading(false);
    };

    const addToList = () => {
        const value = prompt("Enter program");
        if(value && value.trim().length !== 0){
            setData(state => ({
                ...state,
                pList: [...state.pList, value.trim()]
            }));
        }
    };

    const removeFromList = (i) => {
        setData(state => ({
            ...state,
            pList: [...state.pList.filter((obj, index) => i !== index)]
        }));
    };

    React.useEffect(() => {
        fetch();
    }, []);

    const section1 = React.useMemo(() => (
        <React.Fragment>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 6 }}>Section 1</Typography>
            <Box width='95%' position='relative'>
                <img src={data.contactUsImage} className="w-100" alt="banner"/>
                <Box width='100%' position='absolute' bottom={0} zIndex={100} padding={2} display='flex' justifyContent={data.align} id='alif-banner'>
                    {parse(`<${data.tag} style="color: ${data.color}; font-family: ${data.family}; ">${data.s1Title}</${data.tag}>`)}
                </Box>
            </Box>
            <Button 
            variant="contained" 
            onClick={() => inpRef.current?.click()} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 2 }}>Change image</Button>
            <Box height={20}/>
            <br/>
            <Input2 placeholder='Title' value={data.s1Title} onChange={e => handleChange({ s1Title: e })}/>
            <Box height={6}/>
            <MuiColorInput format="hex" value={data.color} onChange={e => handleChange({ color: e })}/>
            <RadioGroup row defaultValue="Barlow" name="radio-buttons-group" value={data.family} onChange={e => handleChange({ family: e.target.value })}>
                <FormControlLabel value="Barlow" control={<Radio />} label="Barlow" />
                <FormControlLabel value="Playfair Display" control={<Radio />} label="Playfair Display" />
            </RadioGroup>
            <RadioGroup row defaultValue="h1" name="radio-buttons-group" value={data.tag} onChange={e => handleChange({ tag: e.target.value })}>
                <FormControlLabel value="h1" control={<Radio />} label="h1" />
                <FormControlLabel value="h2" control={<Radio />} label="h2" />
                <FormControlLabel value="h3" control={<Radio />} label="h3" />
                <FormControlLabel value="h4" control={<Radio />} label="h4" />
                <FormControlLabel value="h5" control={<Radio />} label="h5" />
                <FormControlLabel value="h6" control={<Radio />} label="h6" />
            </RadioGroup>
            <RadioGroup row defaultValue="flex-start" name="radio-buttons-group" value={data.align} onChange={e => handleChange({ align: e.target.value })}>
                <FormControlLabel value="flex-start" control={<Radio />} label="left" />
                <FormControlLabel value="center" control={<Radio />} label="center" />
                <FormControlLabel value="flex-end" control={<Radio />} label="right" />
            </RadioGroup>
        </React.Fragment>
    ));

    const section2 = React.useMemo(() => (
        <React.Fragment>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 2, mt: 6 }}>Section 2</Typography>
            <Box width='95%'>
                <Input2 multiline placeholder='Description in contact us page' sx={{ mt: 2 }} value={data.contactUsDesc} onChange={e => handleChange({ contactUsDesc: e })}/>
                <br/>
                <Input2 placeholder='Phone' sx={{ mt: 2 }} value={data.phone} onChange={e => handleChange({ phone: e })}/>
                <br/>
                <Input2 placeholder='Email' sx={{ mt: 2 }} value={data.email} onChange={e => handleChange({ email: e })}/>
                <br/>
                <Input2 placeholder='Location' sx={{ mt: 2 }} value={data.location} onChange={e => handleChange({ location: e })}/>
                <br/>
                <Input2 multiline placeholder='Description in footer' sx={{ mt: 2 }} value={data.footerDesc} onChange={e => handleChange({ footerDesc: e })}/>
                <br/>
                <Input2 placeholder='Email to be notified' sx={{ mt: 2 }} value={data.enquiryEmail} onChange={e => handleChange({ enquiryEmail: e })}/>
                <br/>
                <Input2 placeholder='WhatsApp number (eg: 64220786007)' sx={{ mt: 2 }} value={data.wa} onChange={e => handleChange({ wa: e })}/>
                <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 2, mt: 6 }}>Hide or unhide pages</Typography>
                <FormGroup>
                    <FormControlLabel control={<Switch checked={data.hajj} onChange={e => handleChange({ hajj: e.target.checked })}/>} label="Hajj" />
                    <FormControlLabel control={<Switch checked={data.umrah} onChange={e => handleChange({ umrah: e.target.checked })}/>} label="Umrah" />
                    <FormControlLabel control={<Switch checked={data.ziyarat} onChange={e => handleChange({ ziyarat: e.target.checked })}/>} label="Ziyarat" />
                </FormGroup>
            </Box>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 2, mt: 6 }}>Program list</Typography>
            <Box width='95%' ml={2}>
                {data.pList?.map((obj, i) => (
                    <Box key={i} className="row" sx={{ alignItems: 'center' }}>
                        <p><b>{i}: </b>{obj}</p>
                        <IconButton onClick={() => removeFromList(i)} sx={{ ml: 2, mt: -2 }}>
                            <DeleteForever sx={{ color: '#ABABAB' }}/>
                        </IconButton>
                    </Box>
                ))}
                <Button 
                variant="contained" 
                onClick={addToList} 
                sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 2 }}>Add new program</Button>
            </Box>
        </React.Fragment>
    ));

    return (

        <Box className='admin-div-body'>
            {loading?<LinearProgress sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 10000 }}/>:null}
            {section1}
            {section2}
            <input ref={inpRef} id='file-input' type='file' accept='image/*' style={{ display: 'none' }} onChange={e =>  handleImage(e)}/>
            <Button 
            variant="contained" 
            onClick={async() => {
                setLoading(true);
                await saveData(data);
                setLoading(false);
            }}
            sx={{ color: '#cba235', borderRadius: 30, backgroundColor: '#2e2d31', position: 'absolute', bottom: 20, right: 20, p: 3.5, fontWeight: 'bold' }}>Save</Button>
            <Box height={200}/>
        </Box>

    );

}

export default React.memo(Contact);