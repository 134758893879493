import * as React from 'react';
import { CircularProgress, Backdrop, Box } from '@mui/material';

const Loader = () => {

  return (

        <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.drawer+1 }}>
            <Box sx={{ width: '100%', height: '100%', backgroundColor: '#2e2d31', color: '#cba235', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress color="inherit" />
            </Box>
        </Backdrop>

    );

};

export default React.memo(Loader);