import React from 'react';
import Slider from "react-slick";
import { Box } from '@mui/material';
import "slick-carousel/slick/slick.css";
import { Loader } from '../../components';
import "slick-carousel/slick/slick-theme.css";

const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
};

const Section1 = ({s1List, len}) => {

    const slideRef = React.useRef(null);
    const refArr = React.useRef([]);
    const index = React.useRef(0);

    const [loading, setLoading] = React.useState(true);

    const next = () => {
        index.current = index.current === len ? 0 : index.current+1;
        slideRef.current?.slickGoTo(index.current);
    };

    const slideFunc = () => {
        if(refArr.current[index.current]===null){
            next();
        }else{
            refArr.current[index.current].defaultMuted = true;
            refArr.current[index.current]?.play();
            if(index.current===0){
                setTimeout(() => {
                    setLoading(false);
                }, 100);
            }
        }
    };

    React.useEffect(() => {
        setTimeout(() => {
            setInterval(slideFunc, 3000);
            if(refArr.current[0]===null){
                setLoading(false);
            }
        }, 250);
    }, []);

    return (

        <React.Fragment>
            <Slider 
            ref={slideRef} 
            className='main-slider'
            {...settings}>

                {s1List?.map((obj, i) => {

                    let align = 'center';
                    if(obj.align==='center') align = 'center';
                    else if(obj.align==='left') align = 'flex-start';
                    else if(obj.align==='right') align = 'flex-end';

                    if(obj.isVid){

                        return (
                            <Box key={i} sx={{ position: 'relative', width: '100%', height: '100vh' }} className='slider-item'>
                                <video ref={ref => refArr.current[i] = ref} muted playsInline width='100%' height='100%' onEnded={next}>
                                    <source src={obj.img} type="video/mp4"/>
                                </video>
                                <div className="carousel-caption d-md-block">
                                    <Box width='100%' height='100%' display='flex' 
                                    flexDirection='column' alignItems={align} justifyContent='center'>
                                        <Box sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }}>
                                            {obj?.pattern[0]===5?
                                            <h5 className='carousel-caption-h5' 
                                            style={{ textAlign: obj.align, color: obj.font1.color, fontSize: obj.font1.size, fontFamily: `"${obj.font1.family}", sans-serif` }}>
                                                {obj.title1}
                                            </h5>:
                                            obj?.pattern[0]===3?
                                            <h3 className='carousel-caption-h3' 
                                            style={{ textAlign: obj.align, color: obj.font2.color, fontSize: obj.font2.size, fontFamily: `"${obj.font2.family}", sans-serif` }}>
                                                {obj.title2}
                                            </h3>:
                                            obj?.pattern[0]===2?
                                            <h2 className='carousel-caption-h2' 
                                            style={{ textAlign: obj.align, WebkitTextStrokeColor: obj.font3.color, WebkitTextStrokeWidth: '1px', fontSize: obj.font3.size, fontFamily: `"${obj.font3.family}", sans-serif` }}>
                                                {obj.title3}
                                            </h2>:null}
                                            {obj?.pattern[1]===5?
                                            <h5 className='carousel-caption-h5' 
                                            style={{ textAlign: obj.align, color: obj.font1.color, fontSize: obj.font1.size, fontFamily: `"${obj.font1.family}", sans-serif` }}>
                                                {obj.title1}
                                            </h5>:
                                            obj?.pattern[1]===3?
                                            <h3 className='carousel-caption-h3' 
                                            style={{ textAlign: obj.align, color: obj.font2.color, fontSize: obj.font2.size, fontFamily: `"${obj.font2.family}", sans-serif` }}>
                                                {obj.title2}
                                            </h3>:
                                            obj?.pattern[1]===2?
                                            <h2 className='carousel-caption-h2' 
                                            style={{ textAlign: obj.align, WebkitTextStrokeColor: obj.font3.color, WebkitTextStrokeWidth: '1px', fontSize: obj.font3.size, fontFamily: `"${obj.font3.family}", sans-serif` }}>
                                                {obj.title3}
                                            </h2>:null}
                                            {obj?.pattern[2]===5?
                                            <h5 className='carousel-caption-h5' 
                                            style={{ textAlign: obj.align, color: obj.font1.color, fontSize: obj.font1.size, fontFamily: `"${obj.font1.family}", sans-serif` }}>
                                                {obj.title1}
                                            </h5>:
                                            obj?.pattern[2]===3?
                                            <h3 className='carousel-caption-h3' 
                                            style={{ textAlign: obj.align, color: obj.font2.color, fontSize: obj.font2.size, fontFamily: `"${obj.font2.family}", sans-serif` }}>
                                                {obj.title2}
                                            </h3>:
                                            obj?.pattern[2]===2?
                                            <h2 className='carousel-caption-h2' 
                                            style={{ textAlign: obj.align, WebkitTextStrokeColor: obj.font3.color, WebkitTextStrokeWidth: '1px', fontSize: obj.font3.size, fontFamily: `"${obj.font3.family}", sans-serif` }}>
                                                {obj.title3}
                                            </h2>:null}
                                        </Box>
                                        <Box sx={{ display: { xs: 'none', md: 'block', lg: 'none' } }}>
                                            {obj?.pattern[0]===5?
                                            <h5 className='carousel-caption-h5' 
                                            style={{ textAlign: obj.align, color: obj.font1.color, fontSize: obj.font1.size, fontFamily: `"${obj.font1.family}", sans-serif` }}>
                                                {obj.title1}
                                            </h5>:
                                            obj?.pattern[0]===3?
                                            <h3 className='carousel-caption-h3' 
                                            style={{ textAlign: obj.align, color: obj.font2.color, fontSize: Math.abs(obj.font2.size-40), fontFamily: `"${obj.font2.family}", sans-serif` }}>
                                                {obj.title2}
                                            </h3>:
                                            obj?.pattern[0]===2?
                                            <h2 className='carousel-caption-h2' 
                                            style={{ textAlign: obj.align, WebkitTextStrokeColor: obj.font3.color, WebkitTextStrokeWidth: '1px', fontSize: Math.abs(obj.font3.size-35), fontFamily: `"${obj.font3.family}", sans-serif` }}>
                                                {obj.title3}
                                            </h2>:null}
                                            {obj?.pattern[1]===5?
                                            <h5 className='carousel-caption-h5' 
                                            style={{ textAlign: obj.align, color: obj.font1.color, fontSize: obj.font1.size, fontFamily: `"${obj.font1.family}", sans-serif` }}>
                                                {obj.title1}
                                            </h5>:
                                            obj?.pattern[1]===3?
                                            <h3 className='carousel-caption-h3' 
                                            style={{ textAlign: obj.align, color: obj.font2.color, fontSize: Math.abs(obj.font2.size-40), fontFamily: `"${obj.font2.family}", sans-serif` }}>
                                                {obj.title2}
                                            </h3>:
                                            obj?.pattern[1]===2?
                                            <h2 className='carousel-caption-h2' 
                                            style={{ textAlign: obj.align, WebkitTextStrokeColor: obj.font3.color, WebkitTextStrokeWidth: '1px', fontSize: Math.abs(obj.font3.size-35), fontFamily: `"${obj.font3.family}", sans-serif` }}>
                                                {obj.title3}
                                            </h2>:null}
                                            {obj?.pattern[2]===5?
                                            <h5 className='carousel-caption-h5' 
                                            style={{ textAlign: obj.align, color: obj.font1.color, fontSize: obj.font1.size, fontFamily: `"${obj.font1.family}", sans-serif` }}>
                                                {obj.title1}
                                            </h5>:
                                            obj?.pattern[2]===3?
                                            <h3 className='carousel-caption-h3' 
                                            style={{ textAlign: obj.align, color: obj.font2.color, fontSize: Math.abs(obj.font2.size-40), fontFamily: `"${obj.font2.family}", sans-serif` }}>
                                                {obj.title2}
                                            </h3>:
                                            obj?.pattern[2]===2?
                                            <h2 className='carousel-caption-h2' 
                                            style={{ textAlign: obj.align, WebkitTextStrokeColor: obj.font3.color, WebkitTextStrokeWidth: '1px', fontSize: Math.abs(obj.font3.size-35), fontFamily: `"${obj.font3.family}", sans-serif` }}>
                                                {obj.title3}
                                            </h2>:null}
                                        </Box>
                                        <Box sx={{ display: { xs: 'block', md: 'none', lg: 'none' } }}>
                                            {obj?.pattern[0]===5?
                                            <h5 className='carousel-caption-h5' 
                                            style={{ textAlign: obj.align, color: obj.font1.color, fontSize: Math.abs(obj.font1.size-3), fontFamily: `"${obj.font1.family}", sans-serif` }}>
                                                {obj.title1}
                                            </h5>:
                                            obj?.pattern[0]===3?
                                            <h3 className='carousel-caption-h3' 
                                            style={{ textAlign: obj.align, color: obj.font2.color, fontSize: Math.abs(obj.font2.size-50), fontFamily: `"${obj.font2.family}", sans-serif` }}>
                                                {obj.title2}
                                            </h3>:
                                            obj?.pattern[0]===2?
                                            <h2 className='carousel-caption-h2' 
                                            style={{ textAlign: obj.align, WebkitTextStrokeColor: obj.font3.color, WebkitTextStrokeWidth: '1px', fontSize: Math.abs(obj.font3.size-45), fontFamily: `"${obj.font3.family}", sans-serif` }}>
                                                {obj.title3}
                                            </h2>:null}
                                            {obj?.pattern[1]===5?
                                            <h5 className='carousel-caption-h5' 
                                            style={{ textAlign: obj.align, color: obj.font1.color, fontSize: Math.abs(obj.font1.size-3), fontFamily: `"${obj.font1.family}", sans-serif` }}>
                                                {obj.title1}
                                            </h5>:
                                            obj?.pattern[1]===3?
                                            <h3 className='carousel-caption-h3' 
                                            style={{ textAlign: obj.align, color: obj.font2.color, fontSize: Math.abs(obj.font2.size-50), fontFamily: `"${obj.font2.family}", sans-serif` }}>
                                                {obj.title2}
                                            </h3>:
                                            obj?.pattern[1]===2?
                                            <h2 className='carousel-caption-h2' 
                                            style={{ textAlign: obj.align, WebkitTextStrokeColor: obj.font3.color, WebkitTextStrokeWidth: '1px', fontSize: Math.abs(obj.font3.size-45), fontFamily: `"${obj.font3.family}", sans-serif` }}>
                                                {obj.title3}
                                            </h2>:null}
                                            {obj?.pattern[2]===5?
                                            <h5 className='carousel-caption-h5' 
                                            style={{ textAlign: obj.align, color: obj.font1.color, fontSize: Math.abs(obj.font1.size-3), fontFamily: `"${obj.font1.family}", sans-serif` }}>
                                                {obj.title1}
                                            </h5>:
                                            obj?.pattern[2]===3?
                                            <h3 className='carousel-caption-h3' 
                                            style={{ textAlign: obj.align, color: obj.font2.color, fontSize: Math.abs(obj.font2.size-50), fontFamily: `"${obj.font2.family}", sans-serif` }}>
                                                {obj.title2}
                                            </h3>:
                                            obj?.pattern[2]===2?
                                            <h2 className='carousel-caption-h2' 
                                            style={{ textAlign: obj.align, WebkitTextStrokeColor: obj.font3.color, WebkitTextStrokeWidth: '1px', fontSize: Math.abs(obj.font3.size-45), fontFamily: `"${obj.font3.family}", sans-serif` }}>
                                                {obj.title3}
                                            </h2>:null}
                                        </Box>
                                    </Box>
                                </div>
                            </Box>
                        );

                    }else{

                        refArr.current[i] = null;

                        return (

                            <Box key={i} sx={{ backgroundImage: `url("${obj.img}")`, position: 'relative', width: '100%', height: '100vh' }} className='slider-item'>
                                <div className="carousel-caption d-md-block">
                                    <Box width='100%' height='100%' display='flex' 
                                    flexDirection='column' alignItems={align} justifyContent='center'>
                                        <Box sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }}>
                                            {obj?.pattern[0]===5?
                                            <h5 className='carousel-caption-h5' 
                                            style={{ textAlign: obj.align, color: obj.font1.color, fontSize: obj.font1.size, fontFamily: `"${obj.font1.family}", sans-serif` }}>
                                                {obj.title1}
                                            </h5>:
                                            obj?.pattern[0]===3?
                                            <h3 className='carousel-caption-h3' 
                                            style={{ textAlign: obj.align, color: obj.font2.color, fontSize: obj.font2.size, fontFamily: `"${obj.font2.family}", sans-serif` }}>
                                                {obj.title2}
                                            </h3>:
                                            obj?.pattern[0]===2?
                                            <h2 className='carousel-caption-h2' 
                                            style={{ textAlign: obj.align, WebkitTextStrokeColor: obj.font3.color, WebkitTextStrokeWidth: '1px', fontSize: obj.font3.size, fontFamily: `"${obj.font3.family}", sans-serif` }}>
                                                {obj.title3}
                                            </h2>:null}
                                            {obj?.pattern[1]===5?
                                            <h5 className='carousel-caption-h5' 
                                            style={{ textAlign: obj.align, color: obj.font1.color, fontSize: obj.font1.size, fontFamily: `"${obj.font1.family}", sans-serif` }}>
                                                {obj.title1}
                                            </h5>:
                                            obj?.pattern[1]===3?
                                            <h3 className='carousel-caption-h3' 
                                            style={{ textAlign: obj.align, color: obj.font2.color, fontSize: obj.font2.size, fontFamily: `"${obj.font2.family}", sans-serif` }}>
                                                {obj.title2}
                                            </h3>:
                                            obj?.pattern[1]===2?
                                            <h2 className='carousel-caption-h2' 
                                            style={{ textAlign: obj.align, WebkitTextStrokeColor: obj.font3.color, WebkitTextStrokeWidth: '1px', fontSize: obj.font3.size, fontFamily: `"${obj.font3.family}", sans-serif` }}>
                                                {obj.title3}
                                            </h2>:null}
                                            {obj?.pattern[2]===5?
                                            <h5 className='carousel-caption-h5' 
                                            style={{ textAlign: obj.align, color: obj.font1.color, fontSize: obj.font1.size, fontFamily: `"${obj.font1.family}", sans-serif` }}>
                                                {obj.title1}
                                            </h5>:
                                            obj?.pattern[2]===3?
                                            <h3 className='carousel-caption-h3' 
                                            style={{ textAlign: obj.align, color: obj.font2.color, fontSize: obj.font2.size, fontFamily: `"${obj.font2.family}", sans-serif` }}>
                                                {obj.title2}
                                            </h3>:
                                            obj?.pattern[2]===2?
                                            <h2 className='carousel-caption-h2' 
                                            style={{ textAlign: obj.align, WebkitTextStrokeColor: obj.font3.color, WebkitTextStrokeWidth: '1px', fontSize: obj.font3.size, fontFamily: `"${obj.font3.family}", sans-serif` }}>
                                                {obj.title3}
                                            </h2>:null}
                                        </Box>
                                        <Box sx={{ display: { xs: 'none', md: 'block', lg: 'none' } }}>
                                            {obj?.pattern[0]===5?
                                            <h5 className='carousel-caption-h5' 
                                            style={{ textAlign: obj.align, color: obj.font1.color, fontSize: obj.font1.size, fontFamily: `"${obj.font1.family}", sans-serif` }}>
                                                {obj.title1}
                                            </h5>:
                                            obj?.pattern[0]===3?
                                            <h3 className='carousel-caption-h3' 
                                            style={{ textAlign: obj.align, color: obj.font2.color, fontSize: Math.abs(obj.font2.size-40), fontFamily: `"${obj.font2.family}", sans-serif` }}>
                                                {obj.title2}
                                            </h3>:
                                            obj?.pattern[0]===2?
                                            <h2 className='carousel-caption-h2' 
                                            style={{ textAlign: obj.align, WebkitTextStrokeColor: obj.font3.color, WebkitTextStrokeWidth: '1px', fontSize: Math.abs(obj.font3.size-35), fontFamily: `"${obj.font3.family}", sans-serif` }}>
                                                {obj.title3}
                                            </h2>:null}
                                            {obj?.pattern[1]===5?
                                            <h5 className='carousel-caption-h5' 
                                            style={{ textAlign: obj.align, color: obj.font1.color, fontSize: obj.font1.size, fontFamily: `"${obj.font1.family}", sans-serif` }}>
                                                {obj.title1}
                                            </h5>:
                                            obj?.pattern[1]===3?
                                            <h3 className='carousel-caption-h3' 
                                            style={{ textAlign: obj.align, color: obj.font2.color, fontSize: Math.abs(obj.font2.size-40), fontFamily: `"${obj.font2.family}", sans-serif` }}>
                                                {obj.title2}
                                            </h3>:
                                            obj?.pattern[1]===2?
                                            <h2 className='carousel-caption-h2' 
                                            style={{ textAlign: obj.align, WebkitTextStrokeColor: obj.font3.color, WebkitTextStrokeWidth: '1px', fontSize: Math.abs(obj.font3.size-35), fontFamily: `"${obj.font3.family}", sans-serif` }}>
                                                {obj.title3}
                                            </h2>:null}
                                            {obj?.pattern[2]===5?
                                            <h5 className='carousel-caption-h5' 
                                            style={{ textAlign: obj.align, color: obj.font1.color, fontSize: obj.font1.size, fontFamily: `"${obj.font1.family}", sans-serif` }}>
                                                {obj.title1}
                                            </h5>:
                                            obj?.pattern[2]===3?
                                            <h3 className='carousel-caption-h3' 
                                            style={{ textAlign: obj.align, color: obj.font2.color, fontSize: Math.abs(obj.font2.size-40), fontFamily: `"${obj.font2.family}", sans-serif` }}>
                                                {obj.title2}
                                            </h3>:
                                            obj?.pattern[2]===2?
                                            <h2 className='carousel-caption-h2' 
                                            style={{ textAlign: obj.align, WebkitTextStrokeColor: obj.font3.color, WebkitTextStrokeWidth: '1px', fontSize: Math.abs(obj.font3.size-35), fontFamily: `"${obj.font3.family}", sans-serif` }}>
                                                {obj.title3}
                                            </h2>:null}
                                        </Box>
                                        <Box sx={{ display: { xs: 'block', md: 'none', lg: 'none' } }}>
                                            {obj?.pattern[0]===5?
                                            <h5 className='carousel-caption-h5' 
                                            style={{ textAlign: obj.align, color: obj.font1.color, fontSize: Math.abs(obj.font1.size-3), fontFamily: `"${obj.font1.family}", sans-serif` }}>
                                                {obj.title1}
                                            </h5>:
                                            obj?.pattern[0]===3?
                                            <h3 className='carousel-caption-h3' 
                                            style={{ textAlign: obj.align, color: obj.font2.color, fontSize: Math.abs(obj.font2.size-50), fontFamily: `"${obj.font2.family}", sans-serif` }}>
                                                {obj.title2}
                                            </h3>:
                                            obj?.pattern[0]===2?
                                            <h2 className='carousel-caption-h2' 
                                            style={{ textAlign: obj.align, WebkitTextStrokeColor: obj.font3.color, WebkitTextStrokeWidth: '1px', fontSize: Math.abs(obj.font3.size-45), fontFamily: `"${obj.font3.family}", sans-serif` }}>
                                                {obj.title3}
                                            </h2>:null}
                                            {obj?.pattern[1]===5?
                                            <h5 className='carousel-caption-h5' 
                                            style={{ textAlign: obj.align, color: obj.font1.color, fontSize: Math.abs(obj.font1.size-3), fontFamily: `"${obj.font1.family}", sans-serif` }}>
                                                {obj.title1}
                                            </h5>:
                                            obj?.pattern[1]===3?
                                            <h3 className='carousel-caption-h3' 
                                            style={{ textAlign: obj.align, color: obj.font2.color, fontSize: Math.abs(obj.font2.size-50), fontFamily: `"${obj.font2.family}", sans-serif` }}>
                                                {obj.title2}
                                            </h3>:
                                            obj?.pattern[1]===2?
                                            <h2 className='carousel-caption-h2' 
                                            style={{ textAlign: obj.align, WebkitTextStrokeColor: obj.font3.color, WebkitTextStrokeWidth: '1px', fontSize: Math.abs(obj.font3.size-45), fontFamily: `"${obj.font3.family}", sans-serif` }}>
                                                {obj.title3}
                                            </h2>:null}
                                            {obj?.pattern[2]===5?
                                            <h5 className='carousel-caption-h5' 
                                            style={{ textAlign: obj.align, color: obj.font1.color, fontSize: Math.abs(obj.font1.size-3), fontFamily: `"${obj.font1.family}", sans-serif` }}>
                                                {obj.title1}
                                            </h5>:
                                            obj?.pattern[2]===3?
                                            <h3 className='carousel-caption-h3' 
                                            style={{ textAlign: obj.align, color: obj.font2.color, fontSize: Math.abs(obj.font2.size-50), fontFamily: `"${obj.font2.family}", sans-serif` }}>
                                                {obj.title2}
                                            </h3>:
                                            obj?.pattern[2]===2?
                                            <h2 className='carousel-caption-h2' 
                                            style={{ textAlign: obj.align, WebkitTextStrokeColor: obj.font3.color, WebkitTextStrokeWidth: '1px', fontSize: Math.abs(obj.font3.size-45), fontFamily: `"${obj.font3.family}", sans-serif` }}>
                                                {obj.title3}
                                            </h2>:null}
                                        </Box>
                                    </Box>
                                </div>
                            </Box>

                        );
                    }
                })}

            </Slider>
            {loading?<Loader/>:null}
        </React.Fragment>

    );

}

export default React.memo(Section1);