import React from "react";
import { firebaseConfig } from './config';
import { getStorage } from "firebase/storage";
import { getData } from "../Admin/Home/helper";
import { getFirestore } from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { initializeApp, getApps, getApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

let app;

if(getApps().length===0) app = initializeApp(firebaseConfig);
else app = getApp();

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LfctZopAAAAAK-FigRet1tDQWXxveJ63WQwmeYJ'),
    isTokenAutoRefreshEnabled: true
});

export const DataContext = React.createContext({});

export const DataProvider = ({ children }) => {

    const [loading, setLoading] = React.useState(true);
    const [user, setUser] = React.useState(null);

    const [data, setData] = React.useState({
        phone: '',
        email: '',
        location: '',
        footerDesc: '',
    });

    const fetchData = async() => {
        setLoading(true);
        await getData(setData);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    React.useEffect(() => {
        fetchData();
        onAuthStateChanged(auth, (user) => {
            if(user){ 
                setTimeout(() => {
                    setUser(true);
                }, 500);
            }
            else setUser(false);
        });
    }, []);

    const contextValue = { user, data, loading };

    return (

        <DataContext.Provider value={contextValue}>
            {children}
        </DataContext.Provider>

    );

};

export const useData = () => React.useContext(DataContext);