import React from 'react';
import { Link } from 'react-router-dom';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { Luggage as LuggageIcon, Apartment as ApartmentIcon, ArrowRightAlt } from '@mui/icons-material';

const Section3 = ({s31List, s32List}) => {

    const refArr = React.useRef([]);
    const refArr2 = React.useRef([]);

    return (

        <section className="tours1 section-padding bg-lightnav pt-5 index2" data-scroll-index="1">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 tab-container">
                        <ul className="nav nav-tabs nav-justified" role="tablist">
                            <li className="nav-item">
                                <a style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} className="nav-link active" data-toggle="tab" href="#duck2" role="tab" aria-controls="duck2" aria-selected="true" aria-expanded="true">
                                    <LuggageIcon sx={{ color: 'inherit' }}/> &nbsp; Umrah Package
                                </a>
                            </li>
                            <li className="nav-item">
                                <a style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} className="nav-link" data-toggle="tab" href="#chicken2" role="tab" aria-controls="chicken2" aria-selected="false">
                                    <ApartmentIcon sx={{ color: 'inherit' }}/> &nbsp; Accommodation
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content mt-3">
                            <div className="tab-pane active" id="duck2" role="tabpanel" aria-labelledby="duck-tab">
                                {s31List?.map((obj, i) => (
                                    <div className="row pt-3" key={i} style={{ marginTop: 16 }}>
                                       {(i%2===0)?null:
                                       <div className="col-md-5 animate-box fadeInUp animated">
                                            <img src={obj?.img} className="img-fluid trs w-100" alt="banner"/>
                                        </div>}
                                        <ReactVisibilitySensor partialVisibility onChange={e => {
                                            if(e){
                                                refArr.current[i].classList?.add('fadeUp');
                                                refArr.current[i].classList?.remove('anim');
                                            }
                                        }}>
                                            <div className="col-md-6 mb-30 anim" ref={ref => refArr.current[i]=ref}>
                                                <h2>{obj?.title}</h2>
                                                <p><strong>{obj?.desc}</strong></p>
                                                <ul style={{ color: "#676977", fontWeight: 400 }}>
                                                    {obj?.list?.map((obj, i) => <li key={i}>{obj}</li>)}
                                                </ul>
                                                <div className='row' style={{ margin: 6 }}>
                                                    <div className="butn-dark mt-30 mb-30">
                                                        <Link to='/contact' data-scroll-nav="1" className="btn-1">
                                                            <span style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>Book Now &nbsp;&nbsp;<ArrowRightAlt sx={{ color: '#FFF' }}/></span>
                                                        </Link>
                                                    </div>
                                                    <div style={{ flex: 1 }}/>
                                                    {obj?.price?.trim()?.length > 0 ?
                                                    <div style={{ marginRight: 26, paddingLeft: 16, paddingRight: 16, paddingTop: 12, paddingBottom: 12, backgroundColor: '#FFF', color: '#000', borderRadius: 12, border: '2px solid #cba235' }}>
                                                        {obj?.price}
                                                    </div> : null}
                                                </div>
                                            </div>
                                        </ReactVisibilitySensor>
                                        {(i%2===0)?
                                       <div className="col-md-5 animate-box fadeInUp animated">
                                            <img src={obj?.img} className="img-fluid trs w-100" alt="banner"/>
                                        </div>:null}
                                    </div>
                                ))}
                            </div>
                            <div className="tab-pane" id="chicken2" role="tabpanel" aria-labelledby="chicken-tab">
                                {s32List?.map((obj, i) => (
                                <div className="row pt-3" key={i}>
                                    {(i%2===0)?null:
                                    <div className="col-md-5 animate-box fadeInUp animated" data-animate-effect="fadeInUp">
                                        <img src={obj?.img} className="img-fluid trs w-100" alt="banner"/>
                                    </div>}
                                    <ReactVisibilitySensor partialVisibility onChange={e => {
                                        if(e){
                                            refArr2.current[i].classList?.add('fadeUp');
                                            refArr2.current[i].classList?.remove('anim');
                                        }
                                    }}>
                                        <div className={`col-md-6 mb-30 anim ${(i%2===0)?'':'offset-md-1'}`} ref={ref => refArr2.current[i]=ref}>
                                            <div className="section-subtitle">{obj?.subtitle}</div>
                                            <div className="section-title">{obj?.title}</div>
                                            <p>{obj?.desc}</p>
                                        </div>
                                    </ReactVisibilitySensor>
                                    {(i%2===0)?
                                    <div className="col-md-5 offset-md-1 animate-box fadeInUp animated" data-animate-effect="fadeInUp">
                                        <img src={obj?.img} className="img-fluid trs w-100" alt="banner"/>
                                    </div>:null}
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );

}

export default React.memo(Section3);