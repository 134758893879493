import React from 'react';
import { Auth } from './Auth';
import { Home } from './Home';
import { useData } from '../hooks';
import { Box } from '@mui/material';
import { Loader } from '../components';

export default function Main() {

    const { user } = useData();

    if(user===null) return <Loader/>;

    return (

        <Box className='admin-body-main' width='100%' bgcolor='#fff9db'>
            { user ? <Home/> : <Auth/> }
        </Box>

    );

};