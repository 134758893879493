import React from 'react';
import { auth } from '../../hooks';
import { Input } from '../../components';
import { addAdminData, getAdminData } from './helper';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { Button, Card, Box, LinearProgress, Dialog, Typography, TablePagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const AddAdmin = () => {

    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [open ,setOpen] = React.useState(false);
    const [data, setData] = React.useState(['_']);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const arr = React.useMemo(() => rowsPerPage > 0 ? data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data);

    const passwd = Date.now().toString(36)+'#!';

    const add = async(val, pval) => {
        setLoading(true);
        await createUserWithEmailAndPassword(auth, val, pval).then(() => {
            addAdminData(val);
        }).catch((e) => {
            alert(`Error: ${e}`);
        });
        setLoading(false);
        setOpen(false);
        setData(state => [val, ...state]);
    };

    React.useEffect(() => {
        getAdminData(setData);
    }, []);

    return (

        <Box width='100%'>
            {(data[0]==='_'||loading)?<LinearProgress sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 10000 }}/>:null}
            <TableContainer component={Paper} sx={{ backgroundColor: '#2e2d31' }}>
                <TablePagination
                ActionsComponent={() => (
                    <Box mt={-4} width={300} display='flex' alignItems='center' justifyContent='center'>
                        <Button 
                        onClick={() => setOpen(true)}
                        variant="contained" 
                        sx={{ color: '#cba235', borderRadius: 2, backgroundColor: '#2e2d31', mt: 4 }}>Add Admin</Button>
                    </Box>
                )}
                page={page} 
                component={Paper} 
                count={data?.length} 
                rowsPerPage={rowsPerPage} 
                onPageChange={handleChangePage} 
                onRowsPerPageChange={handleChangeRowsPerPage} 
                sx={{ backgroundColor: '#2e2d31', color: '#d5d5d6' }}/>
                <Table sx={{ width: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 600, color: '#d5d5d6' }}>Sl. no.</TableCell>
                            <TableCell sx={{ fontWeight: 600, color: '#d5d5d6' }}>Email</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {arr?.map((item, i) => (
                            <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row" sx={{ color: '#d5d5d6' }}>{i+1}</TableCell>
                                <TableCell sx={{ color: '#d5d5d6' }}>{item}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <Card sx={{ width: 380, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', pb: 2, backgroundColor: '#2e2d31' }}>
                    <Input placeholder='Email' value={email} sx={{ mt: 2 }} onChange={e => setEmail(e)}/>
                    <Box width={300} mt={2}>
                        <Typography variant='body1' sx={{ fontWeight: 600, color: '#FFFFFF' }}>Password: {passwd}</Typography>
                        <Typography variant='caption' sx={{ fontWeight: 600, color: '#FFFFFF' }}>Note:</Typography>
                        <br/>
                        <Typography variant='caption' sx={{ fontWeight: 400, color: '#FFFFFF' }}>1) You can change this password in forgot password in admin login page.</Typography>
                        <br/>
                        <Typography variant='caption' sx={{ fontWeight: 400, color: '#FFFFFF' }}>2) Don't add email addresses that you don't know!</Typography>
                        <br/>
                        <Typography variant='caption' sx={{ fontWeight: 400, color: '#FFFFFF' }}>3) You can't delete admin once you have added. Contact developers for help.</Typography>
                    </Box>
                    <Button onClick={() => add(email, passwd)} variant="contained" size='small' sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#cba235', mt: 2 }}>Add</Button>
                </Card>
            </Dialog>
            <Box height={200}/>
        </Box>

    );

};

export default React.memo(AddAdmin);