import React from 'react';
import { Box, BottomNavigation, BottomNavigationAction, IconButton } from '@mui/material';
import { Home as HomeIcon, Info as InfoIcon, Mosque, MosqueOutlined, MosqueTwoTone, PhoneInTalk, OtherHouses, PowerSettingsNew, QueryStats } from '@mui/icons-material';
import Home from './Home';
import About from './About';
import Hajj from './Hajj';
import Umrah from './Umrah';
import Ziyarat from './Ziyarat';
import Contact from './Contact';
import More from './More';
import { auth } from '../../hooks';
import Seo from './Seo';

const Main = () => {

    const [index, setIndex] = React.useState(0);

    return (

        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', backgroundColor: '#fff9db' }}>
            <BottomNavigation
            showLabels
            value={index}
            color='#676977'
            onChange={(event, newValue) => setIndex(newValue)}
            sx={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: '#2e2d31', position: 'fixed' }}>
                <BottomNavigationAction label="Home" icon={<HomeIcon/>} />
                <BottomNavigationAction label="About" icon={<InfoIcon/>} />
                <BottomNavigationAction label="Hajj" icon={<Mosque/>} />
                <BottomNavigationAction label="Umrah" icon={<MosqueOutlined/>} />
                <BottomNavigationAction label="Ziyarat" icon={<MosqueTwoTone/>} />
                <BottomNavigationAction label="Contact Us" icon={<PhoneInTalk/>} />
                <BottomNavigationAction label="More Info" icon={<OtherHouses/>} />
                <BottomNavigationAction label="SEO" icon={<QueryStats/>} />
                <BottomNavigationAction label="Log Out" icon={
                <IconButton onClick={() => auth.signOut()}>
                    <PowerSettingsNew sx={{ color: '#ea5a5a' }}/>
                </IconButton>}/>
            </BottomNavigation>
            {index===0?<Home/>:null}
            {index===1?<About/>:null}
            {index===2?<Hajj/>:null}
            {index===3?<Umrah/>:null}
            {index===4?<Ziyarat/>:null}
            {index===5?<Contact/>:null}
            {index===6?<More/>:null}
            {index===7?<Seo/>:null}
        </Box>

    );

};

export default React.memo(Main);