import { auth } from '../../hooks';
import { signInWithEmailAndPassword } from 'firebase/auth';

export const LogInUser = async(email, passwd) => {
    try{
        await signInWithEmailAndPassword(auth, email, passwd);
    }catch(e){
        alert(`Error: ${e}`);
        return e;
    }
}