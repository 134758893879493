import React from 'react';
import { InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#353438',
        border: '1px solid',
        borderColor: '#353438',
        fontSize: 16,
        width: 320,
        padding: '10px 12px',
        color: '#ABABAB',
        fontFamily: 'inherit',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:focus': {
            boxShadow: '#353438 0 0 0 0.1rem',
            borderColor: '#353438',
        },
    },
}));

const Input = ({placeholder, value, onChange, ...props}) => {

    return (

        <BootstrapInput autoComplete='none' placeholder={placeholder} label={placeholder} variant='filled' value={value} onChange={e => onChange(e.target.value)} {...props}/>

    );

}

export default React.memo(Input);