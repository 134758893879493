import React from 'react';
import { Input2 } from '../../components';
import { getSEOData, saveSEOData } from './helper';
import { Box, Typography, LinearProgress, Button } from '@mui/material';

const Seo = () => {

    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState({
        home: {
            title: '',
            desc: '',
        },
        about: {
            title: '',
            desc: '',
        },
        hajj: {
            title: '',
            desc: '',
        },
        umrah: {
            title: '',
            desc: '',
        },
        ziyarat: {
            title: '',
            desc: '',
        },
        contact: {
            title: '',
            desc: '',
        },
    });

    const handleChange = React.useCallback((obj1, obj2, val) => {
        setData(state => ({
            ...state,
            [obj1]: { ...state?.[obj1], [obj2]: val }
        }));
    }, [setData]);

    const fetch = async() => {
        setLoading(true);
        await getSEOData(setData);
        setLoading(false);
    };

    React.useEffect(() => {
        fetch();
    }, []);

    return (

        <Box className='admin-div-body'>
            {loading?<LinearProgress sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 10000 }}/>:null}
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 2, mt: 2 }}>Home</Typography>
            <Input2 placeholder='Title' value={data.home.title} onChange={e => handleChange('home', 'title', e)}/>
            <Input2 placeholder='Description' sx={{ ml: 4 }} value={data.home.desc} onChange={e => handleChange('home', 'desc', e)}/>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 2, mt: 2 }}>About</Typography>
            <Input2 placeholder='Title' value={data.about.title} onChange={e => handleChange('about', 'title', e)}/>
            <Input2 placeholder='Description' sx={{ ml: 4 }} value={data.about.desc} onChange={e => handleChange('about', 'desc', e)}/>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 2, mt: 2 }}>Hajj</Typography>
            <Input2 placeholder='Title' value={data.hajj.title} onChange={e => handleChange('hajj', 'title', e)}/>
            <Input2 placeholder='Description' sx={{ ml: 4 }} value={data.hajj.desc} onChange={e => handleChange('hajj', 'desc', e)}/>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 2, mt: 2 }}>Umrah</Typography>
            <Input2 placeholder='Title' value={data.umrah.title} onChange={e => handleChange('umrah', 'title', e)}/>
            <Input2 placeholder='Description' sx={{ ml: 4 }} value={data.umrah.desc} onChange={e => handleChange('umrah', 'desc', e)}/>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 2, mt: 2 }}>Ziyarat</Typography>
            <Input2 placeholder='Title' value={data.ziyarat.title} onChange={e => handleChange('ziyarat', 'title', e)}/>
            <Input2 placeholder='Description' sx={{ ml: 4 }} value={data.ziyarat.desc} onChange={e => handleChange('ziyarat', 'desc', e)}/>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 2, mt: 2 }}>Contact Us</Typography>
            <Input2 placeholder='Title' value={data.contact.title} onChange={e => handleChange('contact', 'title', e)}/>
            <Input2 placeholder='Description' sx={{ ml: 4 }} value={data.contact.desc} onChange={e => handleChange('contact', 'desc', e)}/>
            <Button 
            variant="contained" 
            onClick={async() => {
                setLoading(true);
                await saveSEOData(data);
                setLoading(false);
            }} 
            sx={{ color: '#cba235', borderRadius: 30, backgroundColor: '#2e2d31', position: 'absolute', bottom: 20, right: 20, p: 3.5, fontWeight: 'bold' }}>Save</Button>
            <Box height={200}/>
        </Box>

    );

};

export default React.memo(Seo);